//rss feed
.rss-item {
    margin-bottom: 20px;
    .rss-title {
      font-family: 'montserrat';
      text-decoration: underline;
      color:$tertiary_color;
      font-weight: 700;
      &:hover {
        color: #0056b3;
      }
    }
    .rss-info {
      font-size: 15px;
      .rss-date {
        font-style: italic;
        font-size: 14px;
      }
      .rss-summary {
        line-height: 18px;
      }
    }
  }
  