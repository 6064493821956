/**
* Container
* This needs to be cleaned up later and re-organized!!
* Created on 4/26/2022 by Will
*/ 

.wf-container-block {
    padding-left: 0 !important;
    padding-right: 0 !important;
    &.transparent_bg {
        background: transparent !important;
    }
    &.background-cover-center {
        background-size: cover !important;
        background-position: 50% 50% !important;
    }
}

/**
* Containers
* Pulled from other SASS file - possible delete?
*/ 
@include mq( 'tablet', 'max' ) {
    .container, .container-sm {
        max-width: 100% !important;
    }
}