/**
* Google Translate Block
*/ 
.wf-translate {
    margin: 0 auto;
    display: block;
    width: 100%;
    font-size: 16px;
    font-family: $secondary-font;
    
    .gicon {
        max-width: 40px;
        margin-top: 12px;
        margin-right: 12px;
        float: left;
    }
  
    .ga_wrap {
        width: calc(100% - 44px);
        margin: 0;
        padding: 0;
        display: inline;
    }

    .skiptranslate {
        width: 200px;
    }

    &.is_preview {
        display: inline-block !important;
        width: 100%;
    }

    .ga_wrap {
        position: relative;
        width: auto;
    }

    select.goog-te-combo {
        background-color: #ffffff;
        color: #868D96;
        padding: 10px;
        width: 250px;
        border: none;
        font-size: 20px;
        box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
        -webkit-appearance: button;
        appearance: button;
        outline: none;
        background-image: linear-gradient(45deg, transparent 50%, #343A40 50%), linear-gradient(135deg, #343A40 50%, transparent 50%), linear-gradient(to right, #ffffff, #ffffff);
        background-position: calc(100% - 25px) calc(1em + 4px), calc(100% - 20px) calc(1em + 4px), 100% 0;
        background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
        background-repeat: no-repeat;
        -webkit-appearance: none;
      }
}

.goog-logo-link {
    display:none !important;
} 
    
.goog-te-gadget{
    color: transparent !important;
}