.proof-points-wrap {
  .title {
    font-size: 42px;
    line-height: 36px;
    color: #3264A6;
    font-weight: bold;
    text-transform: uppercase;
  }
  .description {
    font-size: 24px;
    line-height: 28px;
    width: 100%;
    max-width: 540px;
    margin: 0 auto;
    font-weight: 400;
  }
  .title,
  .description {
    display: block;
    text-align: center;
    font-family: 'montserrat';
    padding: 5px 0;
  }
  .proof-points{
    @include media-breakpoint-down(sm) {
      // padding: 0 2.5rem;
      margin-bottom: 2.5rem;
    }
    .stats{
      position: relative;
      display: block;
      margin: 0 auto 17px;
      width: 202px;
      height: 202px;

      img{
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        margin-top: -85px;
        margin-left: -85px;
        max-width: 170px;
        height: auto;
      }

      .value{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $tertiary_color;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -64px;
        margin-top: -64px;
        z-index: 2;
        width: 140px;
        height: 140px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: $white;
        border-radius: 140px;
        text-align: center;
      }

      &.text{
        width: 100%;
        height: auto;
        margin-bottom: 17px;

        .value{
          background-color: transparent;
          display: block;
          position: relative;
          top: inherit;
          left: inherit;
          margin: 0 0;
          color: $tertiary_color;
          font-size: 4.375rem;
          line-height: 1;
          width: auto;
          height: auto;

          &:after{
            content: '';
            background-color: $shadow-gray;
            display: block;
            margin: 5px auto 0;
            width: 100px;
            height: 1px;
          }
        }
      }
    }

    .stats-desc{
      font-size: 16px;
      text-align: center;
      line-height: 22px;
      font-weight: 300;
      font-family: $secondary-font;
    }
    &.minimal {
      padding: 20px 0;
      img {
        display: none;
      }
      .stats {
        width: auto;
        height: auto;
        text-align: center;
        position: relative;
      }
      .value {
        background: none;
        color: $defaultblue;
        font-family: $primary-bold-font;
        font-size: 100px;
        border-radius: 0;
        width: auto;
        height: auto;
        margin: 0;
        display: inline-block;
        position: unset;
        &::after {
          position: absolute;
          content: "";
          width: 100px;
          height: 1px;
          background-color: $red;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}