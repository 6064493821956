
.btn {
    font-family: $secondary-font, sans-serif;
    font-variation-settings: 'wght' 700;
    text-transform: uppercase;
    font-size: 18px;
    border-radius: 60px;
    padding: 15px 42px;
    transition: none;
    @media only screen and (max-width: 1200px) {
        padding: 15px 35px;
    }
    &.btn-block {
        padding: 15px;
        display: block;
        width: 100%;
      }
    &.btn-default {
        background-color: $red;
        border: solid $red 2px;
        color: $white;
        min-width: 213px;
        @media only screen and (max-width: 576px) {
            display: block;
            width: 100%;
          }
        &:hover, &:focus {
            background-color: transparent;
            color: $red;
            border: solid $red 2px;
        }
        &.btn-dark-bg {
            &:hover, &:focus {
                background-color: $white;
                color: $red;
                border: solid $red 2px;
            }
        }
    }
  &.btn-primary {
      background-color: $defaultblue;
      border: solid $defaultblue 2px;
      color: $white;
      min-width: 213px;
      @media only screen and (max-width: 576px) {
        display: block;
        width: 100%;
      }
      &:hover, &:focus {
          background-color: $white;
          color: $defaultblue;
          border: solid $defaultblue 2px;
      }
      &.btn-dark-bg {
          background-color: $white;
          border: solid $white 2px;
          color: $defaultblue;
          &:hover, &:focus {
              background-color: transparent;
              border: solid $white 2px;
              color: $white;
          }
      }
  }
  &.btn-secondary {
      background-color: transparent;
      border: solid $defaultblue 2px;
      color: $defaultblue;
      min-width: 213px;
      @media only screen and (max-width: 576px) {
        display: block;
        width: 100%;
      }
      &:hover, &:focus {
          background-color: $defaultblue;
          color: $white;
          border: solid $defaultblue 2px;
      }
      &.btn-dark-bg {
          background-color: transparent;
          border: solid $white 2px;
          color: $white;
          &:hover, &:focus {
              background-color: $white;
              border: solid $white 2px;
              color: $defaultblue;
          }
      }
  }
  &.btn-link {
      color: $defaultblue;
      position: relative;
      text-decoration: none;
      font-variation-settings: 'wght' 500;
      padding: 15px 0;
      &:hover, &:focus {
          text-decoration: underline;
          color: $defaultblue;
      }
      &::after {
          display: inline-block;
          margin-left: 8px;
          vertical-align: inherit;
          font-family: $fontawesome;
          content: "\f105";
          line-height: 0;
          font-size: 18px;
      }
      &.btn-dark-bg {
          color: $white;
      }
  }
  &.btn-specialty-primary {
      background-color: transparent;
      border: solid $defaultblue 1px;
      color: $defaultblue;
      border-radius: 0;
      font-weight: normal;
      position: relative;
      padding: 18px 98px;
      @media only screen and (max-width: 1200px) {
        padding: 18px 35px;
    }
    @media only screen and (max-width: 576px) {
        display: block;
        width: 100%;
      }
      font-variation-settings: 'wght' 500;
      &.btn-block {
        padding: 18px;
      }
      &:hover, &:focus {
          background-color: $defaultblue;
          color: $white;
      }
      &::after {
          display: inline-block;
          margin-left: 8px;
          vertical-align: inherit;
          font-family: $fontawesome;
          content: "\f061";
          line-height: 0;
      }
      &.btn-dark-bg {
          background-color: $white;
          border: solid $defaultblue 2px;
          color: $defaultblue;
          &:hover, &:focus {
              background-color: $defaultblue;
              border: solid $white 2px;
              color: $white;
          }
      }
  }
  &.btn-specialty-secondary {
      background-color: $white;
      border: solid $defaultblue 1px;
      color: #3264A6;
      border-radius: 3px;
      font-variation-settings: 'wght' 500;
      position: relative;
      padding: 11px 21px;
      font-size: 16px;
      text-decoration: underline;
      text-transform: inherit;
      text-align: left;
      @media only screen and (max-width: 576px) {
        display: block;
        width: 100%;
      }
      &.btn-block {
        padding: 18px;
      }
      &:hover, &:focus {
          background-color: $secondaryblue;
          border: solid $defaultblue 1px;
          color: #3264A6;
      }
  }
}

.link-primary {
    color: #3264A6 !important;
    padding: 0 2px;
    &:hover {
        background-color: $defaultblue;
        color: $white !important;
    }
    &.dark-bg {
        color: $white !important;
        padding: 0 2px;
        &:hover {
            background-color: $white;
            color: #3264A6 !important;
        }
    }
}