.pattern-gradient-bg {
    background-image: url('../../images/pattern-default.svg');/* fallback */
        background-image: linear-gradient(to top, #E6E9ED, transparent), url('../../images/pattern-default.svg');
        background-size: cover, 600px 600px;
        @media only screen and (max-width: 768px) {
            padding-bottom: 70px;
        }
        .skew-container-max-width {
            max-width: 1433px;
            margin: auto;
        }
}

.pattern-gradient-bg-secondary {

    background-image: url('../../images/pattern-default.svg');/* fallback */
        background-image: linear-gradient(to top, $white, transparent), url('../../images/pattern-default.svg');
        background-size: cover, 600px 600px;
        @media only screen and (max-width: 768px) {
            padding-bottom: 70px;
        }
}

.pattern-white-bg {
        background-image: url('../../images/pattern-default.svg');
        background-size: 600px 600px;
        @media only screen and (max-width: 768px) {
            padding-bottom: 70px;
        }
}

.parallax-bg-image {
    background-image: url('../../images/parallax-image.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 289px 16px;
    background-position: 50% 50%;
    background-attachment: fixed; 
    @media only screen and (max-width: 768px) {
        padding: 137px 16px;
        background-attachment: scroll;
    }
}

.darkblue-bg {
    background-color: $defaultblue;
}
.white-bg {
    background-color: $white;
}
.secondaryblue-bg {
    background-color: $secondaryblue;
}
.turquoise-bg {
    background-color: $turquoise;
}
.bg-mobile-bleed {
    @media only screen and (max-width: 992px) {
        margin-left: -12px;
        margin-right: -12px;
        &.p-4 {
            padding: 30px 15px !important;
        }
    }
}