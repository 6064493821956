
h1, h2, h3, h4, h5, h6 {
  font-family: $primary-bold-font;
  color: $defaultblue;
  line-height: normal;
}

h1 {
    font-size: 55px;
}
h2 {
    font-size: 38px;
}
h3 {
    font-size: 32px;
}
h4 {
    font-size: 24px;
}
h5 {
    font-size: 18px;
}
h6 {
    font-size: 16px;
}