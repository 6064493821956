.horizontal-filters {
    [type=search] {
        padding: 5px 15px;
        color: #001140;
        border-radius: 3px;
        border: solid 1px #001140;
        min-width: 100%;
        &::placeholder {
            color: #001140;
        }
    }
}
.row.news-listing-cards {

    .news-item.card {
        border-radius: 0;
        border: none;
        outline: 1px solid rgba(0, 0, 0, 0.125);
        &:hover, &:focus {
            outline: #001140 solid 3px;
            .card-title a {
                text-decoration: underline;
            }
            .card-body {
                .btn.btn-link {
                    text-decoration: underline !important;
                    &::after {
                        display: inline-block;
                        margin-left: 12px;
                        vertical-align: inherit;
                        font-family: $fontawesome;
                        font-weight: 400;
                        content: "\f105";
                        font-size: 23px;
                        line-height: 0;
                        opacity: 1;
                    }
                    &:hover {
                        background: transparent;
                    }
                }
            }
        }
        .card-title a {
            font-size: 18px;
            color: $tertiary_color;
            text-decoration: none;
            font-variation-settings: "wght" 700;
        }
        .card-body {
            position: relative;
            padding: 30px;
            p {
                font-family: 'Open Sans Regular';
                font-size: 16px;
            }
            .btn.btn-link {
                padding: 0;
                text-decoration: none !important;
                &::after {
                    display: inline-block;
                    margin-left: 12px;
                    vertical-align: inherit;
                    font-family: $fontawesome;
                    font-weight: 400;
                    content: "\f105";
                    font-size: 23px;
                    line-height: 0;
                    opacity: 0;
                }
                &:hover {
                    background: transparent;
                }
            }
        }
    }
}
