$si_wh: 36px;

.social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  > * {
    flex: 1;
    width: $si_wh;
    height: $si_wh;
    max-width: $si_wh;
    color: $tertiary_color;
    background: $white;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 100%;
    &:hover {
      text-decoration: none;
      background: $tertiary_color;
      color: $white;
    }
  }
}