.employees-template-default.single-employees {
  .employee-profile {
    .employee-email, .employee-phone {
      span {
        width: 160px;
        display: inline-block;
      }
      a {
        color: $tertiary_color;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
