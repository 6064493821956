/**
* === Alert ===
* A Bootstrap 5 alert box
*/
.alert {
  margin-bottom: 0 !important;
  border-radius: 0 !important;
  font-size: 16px;
  &.alert-danger {
    background-color: $red;
    color: $white;
    border: solid 1px $red;
    a {
      color: $white;
      padding: 0 2px;
      &:hover {
        background-color: $defaultblue;
        color: $white;
      }
    }
  }
  &.alert-warning {
    background-color: #E7AB31;
    color: $defaultblue;
    border: solid 1px #E7AB31;
    a {
      color: $defaultblue;
      padding: 0 2px;
      &:hover {
        background-color: $defaultblue;
        color: $white;
      }
    }
  }
  &.alert-dismissible .btn-close {
    color: #222;
    opacity: 1;
  }
}
