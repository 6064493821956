.mountains-wrap {
    position: relative;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    width: 100%;
    height: 450px;
    overflow: hidden;
    z-index: -1;
    background-color: white;
    margin-top: -100px;
    @media only screen and (max-width: 1405px) {
        display: none;
    }
    .mountains {
      position: absolute;
      display: block;
      bottom: -5px;
      width: 100%;
      height: 450px;
      z-index: 0;
  
      .one,
      .two,
      .three,
      .four,
      .five {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: repeat-x;
        background-size: 100%;
      }
  
      .one {
        background-image: url('../../images/Path-4304.png');
        z-index: 0;
        background-position-y: 240px;
        background-size: 916px;
      }
  
      .two {
        background-image: url('../../images/Path-4303.png');
        z-index: -1;
        background-position-y: 180px;
        background-size: 900px;
      }
  
      .three {
        background-image: url('../../images/Path-4302.png');
        z-index: -2;
        background-position-y: 60px;
        background-size: 920px;
      }
  
      .four {
        background-image: url('../../images/Path-4301.png');
        z-index: -3;
        background-position-y: 90px;
        background-size: 900px;
      }
  
      .five {
        background-image: url('../../images/Path-4300.png');
        z-index: -4;
        background-position-y: 10px;
        background-repeat: repeat-x;
        background-size: 900px;
        background-position-x: center;
      }
    }
  }


  
  .mountains-mobile {
    height: 500px;
    background-image: url('../../images/mountains.svg');
    background-position: center center;
    background-repeat: no-repeat;
    display: none;
    @media only screen and (max-width: 1405px) {
        display: block;
    }
  }