
/*
* Placeholders
  How to use:

  input,
  textarea {
      @include input-placeholder {
          color: $gray;
      }
  }

*/
@mixin input-placeholder {
    &.placeholder {
      @content;
    }
  
    &:-moz-placeholder {
      @content;
    }
  
    &::-moz-placeholder {
      @content;
    }
  
    &:-ms-input-placeholder {
      @content;
    }
  
    &::-webkit-input-placeholder {
      @content;
    }
  }