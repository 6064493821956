/*
* In Page Links Menu: Configs
*/
$inpage_col_count_desktop: 3;
$inpage_col_gap_desktop: 20px;
$inpage_col_count_tablet: 2;
$inpage_col_gap_tablet: 15px;
$inpage_col_count_mobile: 1;
$inpage_col_gap_mobile: 10px;


/* INNER PAGE MENU */
.btn-inpage-expand {
  padding-bottom: 0;
  i.fa-bars, i.fa-times {
    font-size: 20px;
  }
}


/*
* In Page Links Menu
*/
.inpage-links-menu {
    background: $tertiary_color;
    color: $white;
    padding-left: 0;
    padding-right: 0;
    font-size: 18px;
    font-weight: bold;
    font-family: $secondary-bold-font;
    .btn.btn-inpage-expand {
      padding: 0;
      color: $white;
      &:hover i {
        transform: translateX(0px);
      }
    }
    .title-info {
        text-transform: uppercase;
        padding: 0px 0 0 15px;
    }
    .inpage-links {
        background: $white;
        .container {
            padding: 30px 0;
        }
    }
    .inpage-top {
        display: grid;
        grid-template-columns: calc(100% - 60px) 40px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .btn-inpage-expand {
        .open { display:inline-block; }
        .closed { display: none; }
        &.collapsed {
            .open { display:none; }
            .closed { display: inline-block; }
        }
    }
    ul {
        list-style: none;
        padding: 0 15px;
        @include columns($inpage_col_count_mobile, $inpage_col_gap_mobile);
        @include mq('tablet-small') {
            @include columns($inpage_col_count_tablet, $inpage_col_gap_tablet);
        }
        @include mq('desktop') {
            @include columns($inpage_col_count_desktop, $inpage_col_gap_desktop);
        }
        li {
            border-bottom: 1px solid $gray-300;
            padding: 10px 0;
            display: inline-block;
            width: 100%;
            a {
                color: $tertiary_color;
                text-decoration: none;
                &:hover {
                  text-decoration: underline;
                }
            }
        }
    }
}

.sidebar-menu-hidden {
  @include mq('phablet-large', 'min') {
    display: none;
  }
}
.sidebar-list-group {
  .sidebar-list-group-title {
    background-color: $tertiary_color;
    padding: 15px 30px;
    text-transform: uppercase;
    font-family: $secondary-bold-font, sans-serif;
    font-size: 18px;
    @include mq('phablet-large', 'max') {
      display: none;
    }
  }
  .sidebar-list-group-wrap {
    border-left: solid 1px $gray-400;
    border-right: solid 1px $gray-400;
    border-bottom: solid 1px $gray-400;
    padding: 0 30px;
    background-color: #E5E6E4;

  @include mq('phablet-large', 'max') {
    display: none;
  }
  ul {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: .25rem;
  list-style: none;
  li {
    border-top: solid 1px $gray-400;
    padding: 15px 0;
    a {
      color: $tertiary_color;
      font-family: $secondary-bold-font, sans-serif;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &:first-child {
      border-top: none;
    }
    > ul {
      list-style: none;
      li {
        border-top: none;
        a {
          font-family: $secondary-font, sans-serif;
        }
        &:last-child {
          border-top: none;
        }
      }
    }
  }
}
}
}
