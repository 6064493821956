/**
* === Expandable Search ===
* Search input component that expands when selected
*/
input.expandable-search {
	outline: none;
	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button {
		display: none;
	}
	&::placeholder {
		color: #fff;
	}
	&::-moz-placeholder {
		color: #fff;
	}
	&::-webkit-input.expandable-search-placeholder {
		color: #fff;
	}
	&[type=search] {
		-webkit-appearance: textfield;
		-webkit-box-sizing: content-box;
		font-family: $primary-bold-font, sans-serif;
		font-size: 16px;
		background: #09142A url(../images/search-icon.svg) no-repeat 29px center;
		background-size: 18px 18px;
		text-transform: none;
		border: solid 1px #09142A;
		padding: 9px 30px 9px 52px;
		width: 80px;
		line-height: 82px;
		font-weight: bold;
		color: #fff;
		-webkit-transition: all .5s;
		-moz-transition: all .5s;
		transition: all .5s;
		@include mq( 'phone', 'max' ) {
			background: #09142A url(../images/search-icon.svg) no-repeat 10px center;
			background-size: 18px 18px;
			text-transform: uppercase;
			border: solid 1px #09142A;
			padding: 9px 10px 9px 32px;
			width: 95px;
			line-height: 82px;
			font-weight: bold;
			color: #fff;
			-webkit-transition: all .5s;
			-moz-transition: all .5s;
			transition: all .5s;
		}
	}
	&[type=search]:focus {
		width: 200px;
		background-color: #09142A;
		border-color: #09142A;
		@media (max-width: 600px) {
			width: 80px;
		}
	}
}