.social-share-wrap {
    margin-top: 20px;
    @media only screen and (min-width: 1100px) {
        margin-top: 120px;
    }

    .social-icon {
      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
          margin: 0 2px;
          padding: 0;
          display: flex;
          text-align: -webkit-match-parent;
          @media only screen and (max-width: 1099px) {
            display: inline-block;
          }
  
  
          a {
            .fa-brands {
              width: 37px;
              height: 37px;
  
              &::before {
                font-size: 14px;
                line-height: 24px;
              }
            }
  
            .fa-brands {
              background: $defaultblue;
              color: $white;
              display: inline-block;
              cursor: pointer;
              margin: 0;
              padding: 6px 0;
              text-align: center;
              position: relative;
              overflow: hidden;
              border-radius: 50%;
              border: solid 1px $defaultblue;
              -webkit-transition: all 0.5s;
              -moz-transition: all 0.5s;
              transition: all 0.5s;
              -webkit-transform: translateZ(0);
            }
  
            &:hover {
              .fa-brands {
                background: $white;
                color: $defaultblue;
              }
            }
          }
        }
      }
    }
  }

  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    float: left;
    margin-left: -90px;
    margin-top: -120px;
    @media only screen and (max-width: 1099px) {
      display: none;
    }
  }

  .sticky-none {
    display: none;
    @media only screen and (max-width: 1099px) {
      display: inherit;
    }
  }
  