/**
 * Set default positioning as a fallback for if the plugin fails
 */
 .jquery-background-video-wrapper {
  position: relative;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  .video-hero--content {
    height: 806px;
    @media only screen and (max-width: 768px) {
      height: 240px;
    }
  }
}
.jquery-background-video {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  -o-object-fit: contain;
  object-fit: contain;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
/**
* Fade in videos
* Note the .js class - so non js users still
* see the video
*/
.js .jquery-background-video {
  opacity: 0;
  -webkit-transition: opacity 300ms linear;
  transition: opacity 300ms linear;
}
.js .jquery-background-video.is-visible {
  opacity: 1;
}
/**
* Pause/play button
*/
.jquery-background-video-pauseplay {
  position: absolute;
  background-color: rgba(1, 1, 1, 0.6);
  border: solid 1px $white;
  border-radius: 50%;
  box-shadow: none;
  width: 38px;
  height: 38px;
  bottom: 30px !important;
  top: inherit !important;
  left: inherit !important;
  right: 40px !important;
  padding: 0;
  cursor: pointer;
  outline: none !important;
  @media only screen and (max-width: 768px) {
    display: none;
  }
}
.jquery-background-video-pauseplay span {
  display: none;
}
.jquery-background-video-pauseplay:after, .jquery-background-video-pauseplay:before {
  content:"";
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.jquery-background-video-pauseplay.play:before {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #FFF;
  margin: 8px 0 0 11px;
}
.jquery-background-video-pauseplay.pause:before, .jquery-background-video-pauseplay.pause:after {
  border-top: 10px solid #FFF;
  border-bottom: 10px solid #FFF;
  border-left: 5px solid #FFF;
  margin: 8px 0 0 10px;
}
.jquery-background-video-pauseplay.pause:after {
  left: 10px;
}

.inner-yt-mobile {
  padding: 43px 16px;
  background-color: rgba(0, 22, 65, 1);
  display: none;
  border-top: solid 3px $red;
  border-bottom: solid 3px $red;
  @media only screen and (max-width: 768px) {
    display: block;
  }
  h1 {
    color: $white;
    font-size: 48px;
    margin-bottom: 30px;
    a {
      color: $white;
      text-decoration: underline;
      text-decoration-color: $red;
    }
  }
  p {
    font-size: 18px;
    color: $white;
  }
}
.ytbg-mobile-btn {
  position: absolute;
  bottom: 20px;
  right: 16px;
  color: $white;
  text-decoration: none;
  font-family: $primary-bold-font;
  background-color: rgba(.69, .69, .69, .6);
  border: solid 1px $white;
  font-size: 18px;
  line-height: 18px;
  padding: 4px 10px;
  display: none;
  &:hover {
    color: $white;
  }
  @media only screen and (max-width: 768px) {
    display: block;
  }
  &::after {
    display: inline-block;
    margin-left: 8px;
    vertical-align: inherit;
    font-family: "Font Awesome 6 Free";
    content: "\f04b";
    line-height: 0;
  }
}