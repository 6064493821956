
.editorial-heading {
    background: $tertiary_color;
    color: $white;
    padding: 20px 0;

    h1, h2, h3, h4, h5, h6 {
        font-family: $primary-bold-font;
        padding: 0;
        margin: 0;
        font-size: 38px;
        color: $white;
    }
}