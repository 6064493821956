/**
* === Page Hero ===
* Description
*/
.page-hero-section {
    @include mq('phablet-large') {
        background-image: url('../images/AdmissionsHeroImage.png');
    }
    .desktop-gradient {
        @include mq('phablet-large', 'max') {
            background: none;
        }
    }
    .top-container {
        padding-right: 20%;
        background-position: right;
        &.top-container-fullwidth {
          padding-right: 0;
        }
        @include mq('phablet-large', 'max') {
            height: 100%;
            position: relative;
            background-image: url('../images/AdmissionsHeroImage.png');
            background-size: cover;
            padding: 0;
            max-width: 100%;
        }
        @include mq('phablet-large') {
            background-position:none;
        }
    }
    .bottom-container {
        padding: 15px;
        width: 100%;
        @include mq('phablet-large') {
            padding-right: 18%;
        }
        @include mq("tablet-wide") {
            padding-right: 21%;
        }
        @include mq('desktop') {
            padding-right: 25%;
            &.bottom-container-fullwidth {
              padding-right: 0;
            }
        }
    }
    .top-content-wrapper {
        @include mq('phablet-large', 'max') {
            position: absolute;
            bottom: 0;
            padding: 15px;
        }
        @include mq('phablet-large') {
            padding-top: 338px;
        }
    }
    h1 {
        @include mq('phablet-large', 'max') {
            font-size: 23px;
            text-decoration: none;
        }
    }
    .lead {
        @include mq('phablet-large', 'max') {
            font-size: 16px !important;
        }
    }
    .hero-grid {
        @include mq('phablet-large', 'max') {
            display: grid;
            grid-auto-rows: 1fr;
        }
        @include mq('phablet-large') {
            padding-bottom: 40px;
        }
    }
    .hero-row-top {
        .img-overlay-gradient {
            @include mq('phablet-large') {
                background: none;
            }
        }
    }
    .hero-row-bottom {
        @include mq('phablet-large', 'max') {
            background-image: url(../images/bg-pattern.jpg);
            background-repeat: repeat;
            background-size: 100px 100px;
        }
        .list-group-item {
            a {
                padding: 15px;
                text-transform: initial;
                @include mq('phablet-large', 'max') {
                    background: #D9DAD8;
                    color: #0F2044 !important;
                    font-size: 18px;
                    font-family: $secondary-bold-font;
                    font-weight: bold;
                }
                &.highlight {
                    background: $primary_color;
                    color: #0F2044 !important;
                }
                span {
                    @include mq('phablet-large', 'max') {
                        color: #0F2044 !important;
                        font-family: $secondary-font;
                    }
                    &::after {
                        @include mq('phablet-large', 'max') {
                            color: #0F2044 !important;
                        }
                    }
                }
            }
        }
    }

}

.front-hero-section {
  .hero-row-bottom {
      @include mq('phablet-large', 'max') {
          background-image: url(../images/bg-pattern.jpg);
          background-repeat: repeat;
          background-size: 100px 100px;
      }
  }
}

/* RESEARCH MICROSITE THEME*/
.wf-theme-research {
    .page-hero-section {
        .top-container,
        .bottom-container {
            padding-right: 0;
        }
        .hero-row-bottom .list-group-item a.highlight {
            background: $gray-200;
            color: $tertiary_color !important;
        }
    }

    .page-hero-section {
        @include mq('phablet-large') {
            background-image: url('../images/GenMiscrositeHero.png');
        }
        .desktop-gradient {
            @include mq('phablet-large', 'max') {
                background: none;
            }
        }
        .top-container {
            @include mq('phablet-large', 'max') {
                background-image: url('../images/GenMiscrositeHero.png');
            }
        }
        .hero-row-bottom {
            @include mq('phablet-large', 'max') {
                background-image: none;
                background-repeat: repeat;
                background-color: $tertiary_color;
            }
            .list-group-item {
                a {
                    @include mq('phablet-large', 'max') {
                        background: transparent;
                        color: $gray-200 !important;
                    }
                }
            }
        }
    }

}
