.helpful-links-events-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 64px;
    .helpful-links-events-left-col, .helpful-links-events-right-col {
        h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
            font-size: 38px;
        }
        background-color: $white;
        padding: 18px 28px 60px;
        flex: 1 0 45%;
        margin-bottom: 0;
        position: relative;
        .btn.btn-link {
            position: absolute;
            bottom: 0;
            right: 28px;
        }
    }
    @media only screen and (max-width: 759px) {
        display: block;
        .helpful-links-events-left-col {
            margin-bottom: 42px;
        }
    }
}

.helpful-links-list-wrap {
    background-color: transparent;
    margin-bottom: 24px;
    .helpful-links-list-menu {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
            margin-bottom: 13px;
            a {
                text-decoration: underline;
                color: $lightblue;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}