.sidebar-menu-wrap {
    background-color: $defaultblue;
    margin-bottom: 27px;
    border: solid 3px $red;
    padding: 8px;
        @media only screen and (max-width: 767px) {
            background-color: transparent;
            margin-bottom: 27px;
            border: none;
            padding: 0;
            position: relative;
            h1, h2, h3, h4, h5, h6 {
                display: none;
            }
        }
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        color: $white;
        border-bottom: solid 3px $red;
        font-size: 32px;
        line-height: 41px;
    }
    .sidebar-menu {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
            a {
                font-size: 18px;
                font-family: $primary-bold-font;
                color: $white;
                line-height: 57px;
                margin-bottom: 8px;
                text-decoration: none;
                display: block;
                width: 100%;
                padding: 0 13px;
                &:hover {
                    background-color: $secondaryblue;
                    color: $defaultblue;
                    &::after {
                        display: inline-block;
                        margin-left: 8px;
                        vertical-align: inherit;
                        font-family: "Font Awesome 6 Free";
                        content: "\f105";
                        line-height: 0;
                        font-size: 18px;
                    }
                }
                &.active {
                    background-color: $secondaryblue;
                    color: $defaultblue;
                    &::after {
                        display: inline-block;
                        margin-left: 8px;
                        vertical-align: inherit;
                        font-family: "Font Awesome 6 Free";
                        content: "\f105";
                        line-height: 0;
                        font-size: 18px;
                    }
                }
                &:last-child {
                    a {
                        margin-bottom: 0;
                    }
                }
            }
        }
        @media only screen and (max-width: 767px) {
            display: none;
        }
    }
    select {
        background-color: $defaultblue;
        font-size: 32px;
        font-family: $primary-bold-font;
        color: $white;
        width: 100%;
        padding: 12px 40px 12px 8px;
        border: $red solid 3px;
        border-radius: 0;
        position: relative;
        background-image: url(../../../assets/images/selectDropdown-hamburger.svg);
        background-repeat: no-repeat;
        background-position: 97% 26px;
        background-size: 27px 18px;
        -webkit-appearance: none;
        -moz-appearance: none;
        display: none;
        @media only screen and (max-width: 767px) {
            display: block;
        }
        option {
            font-size: 18px;
        }
    }
}