 /* imghvr-fold*
   ----------------------------- */
[class^='imghvr-fold'], [class*=' imghvr-fold'] {
	perspective: 50em;
	img {
		transform-origin: 50% 0%;
	}
	figcaption {
		z-index: 1;
		opacity: 0;
	}
	&:hover {
		> img {
			opacity: 0;
			transition-delay: 0;
		}
		figcaption {
			transform: rotateX(0) translate3d(0,0%,0) scale(1);
			opacity: 1;
			transition-delay: $ih-transition-duration * 0.6;
		}
	}
}
 /* imghvr-fold-up
   ----------------------------- */
.imghvr-fold-up {
	> img {
		transform-origin: 50% 0%;
	}
	figcaption {
		transform: rotateX(-90deg) translate3d(0%,-50%,0) scale(0.6);
		transform-origin: 50% 100%;
	}
	&:hover {
		> img {
			transform: rotateX(90deg) scale(0.6) translateY(50%);
		}
	}
}
 /* imghvr-fold-down
   ----------------------------- */
.imghvr-fold-down {
	> img {
		transform-origin: 50% 100%;
	}
	figcaption {
		transform: rotateX(90deg) translate3d(0%,50%,0) scale(0.6);
		transform-origin: 50% 0%;
	}
	&:hover {
		> img {
			transform: rotateX(-90deg) scale(0.6) translateY(-50%);
		}
	}
}
 /* imghvr-fold-left
   ----------------------------- */
.imghvr-fold-left {
	> img {
		transform-origin: 0% 50%;
	}
	figcaption {
		transform: rotateY(90deg) translate3d(-50%,0%,0) scale(0.6);
		transform-origin: 100% 50%;
	}
	&:hover {
		> img {
			transform: rotateY(-90deg) scale(0.6) translateX(50%);
		}
	}
}
 /* imghvr-fold-right
   ----------------------------- */
.imghvr-fold-right {
	> img {
		transform-origin: 100% 50%;
	}
	figcaption {
		transform: rotateY(-90deg) translate3d(50%,0%,0) scale(0.6);
		transform-origin: 0 50%;
	}
	&:hover {
		> img {
			transform: rotateY(90deg) scale(0.6) translateX(-50%);
		}
	}
}