.ytbg-container {
    .ytbg-wrap {
      position: relative;
    
      .content {
        display: table;
        width: 100%;
        min-height: 83vh;
        /* z-index: 1; */
        position: relative;
        @media only screen and (max-width: 1405px) {
          min-height: 93vh;
        }
        @media only screen and (max-width: 768px) {
          min-height: 240px;
        }
        .inner {
          padding: 27px 37px 27px 75px;
          background-color: rgba(0, 22, 65, 0.91);
          max-width: 560px;
          border: solid 3px $red;
          position: absolute;
          bottom: 5%;
          left: -3px;
          @media only screen and (max-width: 768px) {
            display: none;
          }
          h1 {
            color: $white;
            font-size: 48px;
            a {
              color: $white;
              text-decoration: underline;
              text-decoration-color: $red;
            }
          }
          p {
            font-size: 18px;
            color: $white;
          }
        }
      }
    }
    .inner-yt-mobile {
      padding: 43px 16px;
      background-color: rgba(0, 22, 65, 1);
      display: none;
      border-top: solid 3px $red;
      border-bottom: solid 3px $red;
      @media only screen and (max-width: 768px) {
        display: block;
      }
      h1 {
        color: $white;
        font-size: 48px;
        margin-bottom: 30px;
        a {
          color: $white;
          text-decoration: underline;
          text-decoration-color: $red;
        }
      }
      p {
        font-size: 18px;
        color: $white;
      }
    }

    .ytbg-mobile-btn {
      position: absolute;
      bottom: 20px;
      right: 16px;
      color: $white;
      text-decoration: none;
      font-family: $primary-bold-font;
      background-color: rgba(.69, .69, .69, .6);
      border: solid 1px $white;
      font-size: 18px;
      line-height: 18px;
      padding: 4px 10px;
      display: none;
      @media only screen and (max-width: 768px) {
        display: block;
      }
      &::after {
        display: inline-block;
        margin-left: 8px;
        vertical-align: inherit;
        font-family: "Font Awesome 6 Free";
        content: "\f04b";
        line-height: 0;
      }
    }
    .video-background-controls {
      z-index: inherit !important;
    }
    
    .video-background-controls button {
      font-size: 28px;
      display: inline-block;
      padding: 0px;
      margin: 0px;
      border-radius: 16px;
      line-height: 32px;
      border: none;
      background: none;
      -webkit-appearance: none;
      color: white;
      filter: drop-shadow(0px 0px 1px black);
      cursor: pointer;
      opacity: 1;
      margin-left: 10px;
      @media only screen and (max-width: 768px) {
        display: none;
      }
      i.fa {
        width: 38px;
        height: 38px;
        background-color: rgba(.69, .69, .69, .6);
        &.fa-play {
          overflow: hidden;
          border-radius: 50%;
          border: solid 1px #ffffff;
          padding: 5px 0 5px 6px;
        }
        &.fa-pause {
          overflow: hidden;
          border-radius: 50%;
          border: solid 1px #ffffff;
          padding: 5px;
        }
      }
    }
    
    .video-background-controls button:hover {
      opacity: 0.5;
    }
    
    iframe {
      transition: opacity 500ms ease-in-out;
      transition-delay: 250ms;
    }
    
}
