.form-block {
  padding: 33px 28px;
  .invalid-feedback {
    background-color: $red;
    padding: 5px 10px;
    color: $white;
  }
  .was-validated .form-control:invalid, .form-control.is-invalid {
    border-color: $red;
  }
  @media only screen and (max-width: 768px) {
    padding: 14px;
  }
  
  &.darkblue-bg {
    .title {
      color: $white;
    }
  }
  .title {
    font-size: 32px;
    line-height: 39px;
    font-family: $primary-bold-font;
    color: #464646;
  }
  .form-group {
    padding: 10px 0;
  }
  .check-form-group {
    display: grid;
    font-size: 18px;
    line-height: 21px;
    .form-group {
      padding: 0;
    }
    input[type="checkbox"],
    input[type="radio"] {
      /* Add if not using autoprefixer */
      -webkit-appearance: none;
      /* Remove most all native input styles */
      appearance: none;
      /* For iOS < 15 */
      background-color: var(--form-background);
      /* Not removed via appearance */
      margin: 0;
      font: inherit;
      color: currentColor;
      width: 26px;
      height: 26px;
      border: 0.15em solid currentColor;
      border-radius: 0.15em;
      transform: translateY(-0.075em);
      display: inline-grid;
      place-content: center;
      &::before {
        content: "";
        width: 0.65em;
        height: 0.65em;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        transform: scale(0);
        transform-origin: bottom left;
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em var(--form-control-color);
        background-color: CanvasText;
      }
      &:checked {
        background: $white;
        border-color: $white;
      }
      &:checked::before {
        transform: scale(1);
        background-color: $defaultblue;
      }
      &:focus {
        /* outline: max(2px, 0.15em) solid currentColor;
        outline-offset: max(2px, 0.15em); */
      }
      &:disabled {
        --form-control-color: var(--form-control-disabled);
        color: var(--form-control-disabled);
        cursor: not-allowed;
      }
    }
    input[type="radio"] {
      border-radius: 100%;
    }
    label {
      padding-left: 20px;
    }
    .custom-control {
      padding: 8px 0;
    }
    .custom-control-label {
      display: inline-grid;
      width: calc(100% - 60px);
    }
  }
  .form-control,
  .form-select {
    padding: 0.7rem 1rem;
    border: 1px solid #BFBFBF;
    background-color: #F4F4F4;
    font-size: 18px;
    line-height: 21px;
    border-radius: 3px;
    &::placeholder {
      color: #222222;
    }
    &:focus {
      &::placeholder {
        opacity: 38%;
      }
    }
  }
  label {
    padding-bottom: 0.4rem;
  }
  &.form-light-bg {
    background: $white;
    border: 1px solid #BFBFBF;
  }
  &.form-transparent-bg {
    background: none;
    border: none;
    .form-control,
    .form-select {
      background-color: $white;
    }
  }
  &.form-dark-bg {
    background: #3264A6;
    border: 1px solid #3264A6;
    color: $white;
    .title {
      color: $white;
    }
    label {
      color: $white !important;
    }
    input[type="checkbox"],
    input[type="radio"] {
      &:checked {
        background: #FFCE00;
        border-color: #FFCE00;
      }
      &:checked::before {
        background-color: #3264A6;
      }
    }
  }
}