/*
=== Gradient Over Image ===

How to Use: 
.image {
    height: 100vh;
    @include gradient-over-image("https://i.imgur.com/UNV29z8.jpeg");
}

HTML: 
<div class="image"></div>

*/
 
@mixin gradient-over-image($img) {
    background-size: cover;
    background-repeat: no-repeat;
    background-image: linear-gradient(
        to bottom,
        rgba(white, 0.5) 10%,
        rgba(white, 1) 90%
      ),
      url("#{$img}");
  }
  
