.image-picker {
  @include media-breakpoint-down(sm) {
    padding: 2rem 1rem;
  }
  .heading{
    position: relative;
    padding-left: 55px;
    line-height: 1;

    @include media-breakpoint-down(sm) {
        font-size: 2rem;
    }

    img{
        position: absolute;
        left: 0;
        top: 4px;
        z-index: 1;
        height: 62px;
        width: auto;

        @include media-breakpoint-down(md) {
          height: 44px;
        }
    }

  }

  .col-lg-6{
    &:last-child{
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
      .image-hover-destination{
          @include media-breakpoint-down(md) {
              display: none;
          }
      }
  }

  p{
    @include media-breakpoint-down(sm){
      font-size: 1rem !important;
    }
  }
  .link-list{
      list-style-type: none;
      margin: 0 0;
      padding: 0 0;

      li{
          line-height: 1;
          margin-bottom: 1.188rem;

          a{
              position: relative;
              font-family: 'montserrat';
              font-weight: 700;
              color: $tertiary_color;
              text-decoration: none !important;

              &:before{
                  display: none;
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: -32px;
                  margin-top: -9px;
                  width: 0;
                  height: 0;
                  border-left: 10px solid transparent;
                  border-right: 10px solid transparent;
                  border-top: 18px solid #D73882;
                  transform: rotate(-90deg);
              }

              &:hover{
                  text-decoration: underline !important;

                  &:before{
                      display: block;
                  }
              }
          }
      }
  }
}  