/**
* Right to left / Left to right Direction controls
*/
@mixin ltr {
    @if $layout-direction==ltr {
      @content;
    }
  }
  
  @mixin rtl {
    @if $layout-direction==rtl {
      @content;
    }
  }