
.multi-directional {
    display: grid;
    /* grid-template-columns: repeat(3, 100px); */
    /* grid-template-rows: repeat(3, 100px); */
    height: 100vh;
    width: 100vw;
    gap: 10px;
    grid-template-areas:
      "a b c"
      "d e f"
      "g h i";
    /* align-content: space-between; */
    /* justify-content: space-around; */
    div {
        padding: 10px;
    }

    .top-left { grid-area: a; }
    .top-center { grid-area: b; }
    .top-right { grid-area: c; }
    .center-left { grid-area: d; }
    .center-center { grid-area: e; }
    .center-right { grid-area: f; }
    .bottom-left { grid-area: g; }
    .bottom-center { grid-area: h; }
    .bottom-right { grid-area: i; }
}
