
@media only screen and (min-width: 769px) {
    .skew-container {
      overflow: hidden;
      width: 100%;
      padding: 60px;
      .skew-col-left, .skew-col-right {
        
      }
      .skew-col-left {
        
      }
      .skew-col-right {
        
      }
      .skew-content {
        font-family: Lato, Arial, Helvetica, Roboto, sans-serif;
        font-weight: 300;
        font-size: 15px;
        line-height: 1;
        color: #CCCCCC;
        display: block;
        padding: 12px 40px;
        text-decoration: none;
        -webkit-transform: skew(5deg);
        -moz-transform: skew(5deg);
        -o-transform: skew(5deg);
      }
  
      .skew-card {
        float: left;
        display: inline-block;
        -webkit-transform: skew(-5deg);
        -moz-transform: skew(-5deg);
        -o-transform: skew(-5deg);
        overflow: hidden;
        position: relative;
        border: 0;
        color: #CCCCCC;
        width: 23%;
        height: 475px;
        outline: 10px transparent solid;
        margin-right: 8px;
  
        &:hover, &:focus-within {
          outline: solid 6px #E91540;
          cursor: pointer;
  
          .skew-content-position {
            background-image: linear-gradient(transparent, transparent, transparent, rgba(0, 16, 47, 0.9), rgba(0, 16, 47, 0.9), #00102F, #00102F);
  
            .skew-content {
              h2 {
                a {
                  text-decoration: underline;
                }
              }
  
              p {
                display: inherit;
              }
            }
          }
        }
  
        .skew-content-position {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-image: linear-gradient(transparent, transparent, transparent, transparent, rgba(0, 16, 47, 0.9), #00102F);
  
          .skew-content {
            font-family: Lato, Arial, Helvetica, Roboto, sans-serif;
            font-weight: 300;
            font-size: 15px;
            line-height: 1;
            color: #CCCCCC;
            display: block;
            padding: 12px 15px;
            text-decoration: none;
            -webkit-transform: skew(5deg);
            -moz-transform: skew(5deg);
            -o-transform: skew(5deg);
            position: absolute;
            bottom: 0;
  
            h2 {
              margin: 0 0 12px;
              line-height: .8;
  
              a {
                font-family: $primary-bold-font;
                color: #ffffff;
                text-decoration: none;
                @include fluid-type(48.063rem, 90rem, 1.25rem, 1.688rem);
              }
            }
  
            p {
              color: #ffffff;
              font-family: "Open Sans", sans-serif;
              font-weight: 400;
              font-size: 17px;
              line-height: 1.2;
              margin: 0 0 5px;
              display: none;
            }
          }
        }
  
        img {
          transform: skew(5deg) !important;
          -webkit-transform: skew(5deg) !important;
          -moz-transform: skew(5deg) !important;
          -o-transform: skew(5deg) !important;
          position: absolute;
          left: -60%;
          width: auto;
          height: 100%;
          max-width: inherit;
          @media only screen and (max-width: 992px) {
            left: -90%;
          }
        }
        .mobile-heading {
          display: none;
        }
      }
    }
  }
  
  @media only screen and (max-width: 768px) {
    .skew-container {
      padding: 80px 0 50px;
      .skew-col-left {
        margin: auto;
        width: fit-content;
      }
      .skew-card {
        background-color: $defaultblue;
        max-width: 328px;
        margin-bottom: 15px;
        position: relative;
  
        .skew-content-position {
          padding: 15px 113px 15px 15px;
  
          .skew-content {
            color: $white;
  
            h2 {
              margin: 0 0 12px;
              display: none;
  
              a {
                font-family: $primary-bold-font;
                color: #ffffff;
                text-decoration: none;
                font-size: 22px;
                text-decoration: underline;
  
                &:hover {
                  text-decoration: underline;
                }
              }
            }
  
            p {
              color: #ffffff;
              font-family: "Open Sans", sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 1.2;
              margin: 0 0 5px;
            }
          }
        }
  
        img {
          width: 98px;
          position: absolute;
          right: 15px;
          bottom: 15px;
        }
        .mobile-heading {
          display: block;
          padding: 0 15px;
          margin-bottom: 0;
          a {
            color: $white;
            text-decoration: none;
            font-size: 22px;
            text-decoration: underline;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  