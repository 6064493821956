/**
* === Layouts ===
* CSS related to various sub-components, and page related layouts
*/

@import 'layouts/forms';
@import 'layouts/tables';
@import 'layouts/multi-directional';
@import 'layouts/custom_spacing';
@import 'layouts/video_bg';
@import 'layouts/mega_menu';
@import 'layouts/videos';