/*
* Global Settings throughout the site
*/

body {
    background: $white;
    color: #222222;
    &.error404 {
        background: $gray-200;
    }
    &::after {
        display: block;
        overflow: hidden;
        width: 0;
        height: 0;
        content: url('../../images/enroll-icon.svg') url('../../images/transfer-icon.svg') url('../../images/enroll-icon-hover.svg') url('../../images/transfer-icon-hover.svg');
    }
}

img {
    max-width: 100%;
    height: auto;
}

.bd-black {
    background-color: $black !important;
    color: $white !important;
}

.bd-white {
    background-color: $white !important;
    color: $black !important;
}

a {
    color: $lightblue;
    &:hover {
        color: $lightblue;
        text-decoration: none;
    }
}

.clickable-div {
    cursor: pointer;
}

.mobile-display-block {
    display: none;
    @media only screen and (max-width: 991px) {
        display: block;
        padding-bottom: 35px;
        border-bottom: 2px solid #E6E9ED;
        margin-bottom: 24px;
    }
}

.mobile-display-none {
    @media only screen and (max-width: 991px) {
        display: none;
    }
}