/**
* === Footer ===
* Styles associated with the Footer block
*/
.site-footer {
    background-color: #35689B;
    .primary-footer-wrap {
        padding: 0 15px 60px;
        .footer-info-col-left {
            @media only screen and (min-width: 769px) {
                border-right: solid 1px $white;
                width: 28%;
            }
            @media only screen and (max-width: 768px) {
                border-bottom: solid 1px $white;
                margin-bottom: 30px;
                padding-bottom: 20px;
            }
            
        }
        .footer-info-col-right {
            @media only screen and (min-width: 769px) {
                width: 72%;
            }
        }
        .footer-heading, .footer-address, .footer-phone, .footer-email {
            color: $white;
            @media only screen and (max-width: 768px) {
                text-align: center;
            }
            a {
                color: $white;
            }
        }
        .footer-heading {
            font-size: 18px;
            font-family: $secondary-font, sans-serif;
            font-variation-settings: 'wght' 700;
        }
        .footer-address {
            font-size: 14px;
            span {
                display: block;
            }
        }
        .footer-phone {
            font-size: 16px;
            font-variation-settings: 'wght' 700;
            a {
                font-variation-settings: 'wght' 300;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .footer-email {
            font-size: 16px;
            font-variation-settings: 'wght' 700;
            a {
                font-variation-settings: 'wght' 300;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    
        .footer-primary-menu {
            list-style-type: none;
            padding: 0;
            margin: 0;
            @media only screen and (min-width: 769px) {
                columns: 2;
                -webkit-columns: 2;
                -moz-columns: 2;
                padding: 0 0 0 40px;
            }
            @media only screen and (max-width: 768px) {
                text-align: center;
            }
            li {
                margin-bottom: 14px;
                a {
                    color: $white;
                    text-decoration: none;
                    font-size: 16px;
                    line-height: 22px;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    
        .footer-social {
            @media only screen and (min-width: 769px) {
                float: right;
            }
        .social-icon {
            ul {
                margin: 0;
                padding: 0;
                list-style-type: none;
                @media only screen and (max-width: 768px) {
                    width: 100%;
                    margin: auto;
                    display: flex;
                }
                li {
                    margin: 0 2px;
                    padding: 0;
                    display: inline-block;
                    text-align: -webkit-match-parent;
                    @media only screen and (max-width: 768px) {
                        flex: 1;
                        text-align: center;
                    }
                    a {
                        .fa-brands {
                            width: 37px;
                            height: 37px;
                            &::before{
                                font-size: 14px;
                                line-height: 24px;
                            }
                        }
            
                        .fa-brands {
                            background: transparent;
                            color: $white;
                            display: inline-block;
                            cursor: pointer;
                            margin: 0;
                            padding: 6px 0;
                            text-align: center;
                            position: relative;
                            z-index: 1;
                            overflow: hidden;
                            border-radius: 50%;
                            border: solid 1px $white;
                            -webkit-transform: translateZ(0);
                        }
                        &:hover {
                            .fa-brands {
                                background: $white;
                                color: $defaultblue;
                            }
                        }
                    }
                }
            }
        }
        }
    }
    .secondary-footer-wrap {
        padding: 18px 15px;
        background-color: #E6E9ED;
        color: $defaultblue;
        .container {
            .footer-logos {
                list-style-type: none;
                padding: 0;
                margin: 0;
                display: flex;
                flex-wrap: wrap;
                text-align: center;
                li {
                    flex: 1;
                    @media only screen and (max-width: 768px) {
                        flex: 1 0 50%;
                        margin-bottom: 15px;
                    }
                }
            }
            .footer-copyright {
                list-style-type: none;
                padding: 0;
                margin: 24px 0;
                display: flex;
                @media only screen and (max-width: 768px) {
                    display: block;
                }
                li {
                    &:first-child {
                        margin-right: 102px;
                        @media only screen and (max-width: 768px) {
                            margin-right: 0;
                        }
                    }
                    a {
                        color: $defaultblue;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
            p {
                font-size: 14px;
            }
        }
    }
}