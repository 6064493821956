.carousel{
  @include media-breakpoint-down(sm){
    margin-bottom: 10%;
  }
}
.carousel-control-prev-icon, .carousel-control-next-icon {
    width: 50px;
    height: 50px;
    background-size: 50%;
    background-color: rgba(0,0,0,.8);
    border-radius: 50%;
}

.carousel-control-prev, .carousel-control-next {
  opacity: 1;
  color: $tertiary_color !important;
  text-decoration: none !important;
  background-color: transparent;
  border-radius: 53px;
  width: 53px;
  height: 53px;
  top: 50%;
  margin-top: -53px;

  @include media-breakpoint-down(sm){
    bottom: -18px;
    top: inherit;
    margin-top: 0;
  }

  &:hover, &:focus {
    color:$tertiary_color;
    background-color: $shadow-gray;
    opacity: 1;
  }
  .fas {
    font-size: 28px;
    color: inherit;
  }
}
.carousel-indicators {
  @include media-breakpoint-down(md){
    bottom:-15px;
    z-index: 2;
  }
}
.carousel-indicators li {
    width: 15px;
    height: 15px;
    background-color: $shadow-gray;
    opacity: 1;
    border: solid 1px $tertiary_color;
    border-radius: 50%;
      @include media-breakpoint-down(sm){
        margin-left: 6px;
        margin-right: 6px;
      }
}

.carousel-indicators li.active {
    background-color: $secondary_color;
    border: solid 1px $tertiary_color;
}


.carousel-padding .carousel-item {
  padding: 0 14% 9%;

  @include media-breakpoint-down(sm) {
    padding: 0 0 14%;
  }
}

.slider.slick-center-mode {
  .slick-slide {
    opacity: 0.6;
    padding: 15px;
  }
  .slick-slide {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    .card-body {
      display: none;
    }
  }
  .slick-slide.slick-current {
    opacity: 1;
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
    z-index: 10;
    .card-body {
      display: block;
      padding: 0 0 40px 0;
      h6 {
        margin: 6px 0 0 0;
      }
      p {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}


.play-pause{
  display: inline-block;
  position: absolute;
  bottom: 15px;
  right: 0;
  z-index: 16;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 23%;
  list-style: none;
}
.play-pause:hover svg{
  fill: $secondary_color;
}

.play-pause .play{
  display: none;
}

.play-pause.pause .play{
  display: inline-block;
}

.play-pause.pause .pause{
  display: none;
}

.play-pause svg{
  width: 15px;
  fill: $tertiary_color;
}