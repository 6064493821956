.bg-pattern-gray,
.bg-pattern-gray {
    background-image: url('../images/bg-pattern.jpg') !important;
    background-repeat: repeat !important;
    background-size: 100px 100px !important;
}
  
.bg-pattern-white {
    background-image: url(../images/bg-pattern-white.jpg) !important;
    background-repeat: repeat !important;
    background-size: 100px 100px !important;
}