/*
* Responsive Ratio
  How to use:

  div {
    @include responsive-ratio(16,9);
  }

*/

@mixin responsive-ratio($x, $y, $pseudo: false) {
    $padding: unquote(($y / $x) * 100 + '%');
  
    @if $pseudo {
      &:before {
        @include pseudo($pos: relative);
        width: 100%;
        padding-top: $padding;
      }
    }
  
    @else {
      padding-top: $padding;
    }
  }