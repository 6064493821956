/**
* === Block Image ===
* Used in the 5+ Block Image Section
*/
.wf-block-img {
    figure {
        margin: 0;
    }
    img {
        width: 100%;
    }
}