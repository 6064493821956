/*
* Breakpoints
  How to use (this uses the variable in the globals file):

  .site-header {
      padding: 2rem;
      font-size: 1.8rem;
      @include mq('tablet-wide') {
          padding-top: 4rem;
          font-size: 2.4rem;
      }
  }

*/
@mixin mq($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
      $width: map_get($breakpoints, $width);
  
      @if $type==max {
        $width: $width - 1px;
      }
  
      @media only screen and (#{$type}-width: $width) {
        @content;
      }
    }
  }