.unordered-list-default, .unordered-list-primary, .ordered-list-default {
  li {
    margin-bottom: 9px;
    font-size: 18px;
    font-family: $secondary-font;
    position: relative;
    a {
      color: $lightblue;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.unordered-list-default {
  list-style-type: none;
  li {
    &::marker {
      color: $lightblue;
    }
    &::before {
      content: '';
      width: 8px;
      height: 8px;
      background: $lightblue;
      border-radius: 50%;
      left: -17px;
      top: 9px;
      position: absolute;
    }
    ul li {
      margin-left: 30px;
      &::marker {
        color: $defaultblue;
      }
      &::before {
        content: '';
        width: 8px;
        height: 8px;
        background: $white;
        border: solid 2px $defaultblue;
        border-radius: 50%;
        left: -17px;
        top: 9px;
        position: absolute;
      }
    }
  }
}

.unordered-list-primary {
  li {
    &::marker {
      color: $defaultblue;
    }
    ul li {
      margin-left: 30px;
    }
  }
}

.ordered-list-default {
  li {
    &::marker {
      font-family: $secondary-font;
      font-variation-settings: "wght" 700;
    }
    ul {
      list-style-type: lower-latin;
    }
  }
}