.tribe-events-view,
.tribe_events-template-default {
  background-image: url(../images/bg-pattern.jpg);
  background-repeat: repeat;
  background-size: 100px 100px;
}

.tribe-events-single-event-title,
.tribe-events-related-events-title {
  font-family: $primary-bold-font, sans-serif !important;
  text-transform: uppercase;
}

.tribe-events-schedule h2.tribe-events-schedule__datetime {
  font-family: $secondary-font, sans-serif;
  font-size: 16px;

  .tribe-events-schedule__date {
    font-size: 16px;
  }
}

.tribe-events-schedule {
  font-family: $secondary-font, sans-serif !important;
  font-size: 16px !important;
  color: $tertiary_color !important;
}

.tribe-events-schedule__all-day,
.tribe-events-schedule__separator,
.tribe-events-schedule__timezone {
  color: $tertiary_color;
}

/* calendar date */
.tribe-common .tribe-common-h4,
.tribe-common .tribe-common-h5,
.tribe-common .tribe-common-h6 {
  font-family: $primary-bold-font, sans-serif !important;
}

.tribe-events .tribe-events-calendar-list__event-date-tag-weekday,
.tribe-common .tribe-common-h7,
.tribe-common .tribe-common-h8,
.tribe-common p,
.tribe-events-calendar-list__event-venue-address,
.tribe-events-calendar-list__event-datetime {
  font-family: $secondary-font, sans-serif;
}

.tribe-events-calendar-list__event-datetime,
.tribe-events-calendar-list__event-venue.tribe-common-b2 {
  font-size: 18px !important;
}

.tribe-events-calendar-list__event-description.tribe-common-b2.tribe-common-a11y-hidden p {
  font-size: 18px;
}

.tribe-events-calendar-list__event-date-tag-weekday {
  font-size: 14px !important;
}

.tribe-common .tribe-common-b3 {
  font-family: $secondary-font, sans-serif;
  color: #262626;
}

.tribe-common .tribe-common-h3,
.tribe-common--breakpoint-medium.tribe-common .tribe-common-h4--min-medium a,
.tribe-common,
.tribe-events-calendar-list__event-venue-title.tribe-common-b2--bold,
.tribe-events-calendar-list__event-datetime-featured-text.tribe-common-a11y-visual-hide {
  font-family: $secondary-bold-font, sans-serif;
}

.tribe-common--breakpoint-medium.tribe-common .tribe-common-h4--min-medium a {
  font-size: 24px;
}

.tribe-common--breakpoint-medium.tribe-common .tribe-common-h3 {
  font-size: 18px;
}

.tribe-events .tribe-events-calendar-list__month-separator:after {
  background-color: $tertiary_color;
  height: 2px;
}

/* find events button */
.tribe-common .tribe-common-c-btn,
.tribe-common a.tribe-common-c-btn {
  background-color: $tertiary_color;
}

/* filter pills */
.tribe-filter-bar {
  .tribe-filter-bar-c-pill--button .tribe-filter-bar-c-pill__pill {
    background-color: $gray-200;
  }

  .tribe-filter-bar-c-pill__pill {
    &:hover {
      border: 1px solid $gray-700 !important;
      background-color: $white !important;
    }
  }
}

.tribe-events {
  .tribe-events-calendar-month__day--current {
    .tribe-events-calendar-month__day-date {
      background-color: $tertiary_color;
    }

    .tribe-events-calendar-month__day-date,
    .tribe-events-calendar-month__day-date-link,
    .tribe-events-calendar-month__day-date-daynum {
      color: $white !important;
    }
  }
}

.tribe-events .tribe-events-calendar-month__day-date-link,
.tribe-common .tribe-common-h4 {
  color: #626262;
}

/* calendar body */

.tribe-common--breakpoint-medium.tribe-events {
  .tribe-events-calendar-month__body {
    border-top: 1px solid $gray-700;
    background-color: $gray-200;
  }

  .tribe-events-calendar-month__day {
    border-color: $gray-700;
  }

  .tribe-events-calendar-month__week {
    border-left: 1px solid $gray-700;
  }
}

.tribe-common-g-row.tribe-events-calendar-list__event-row {
  .tribe-events-calendar-list__event {
    padding: 24px;
  }

  &:nth-child(odd) {
    .tribe-events-calendar-list__event {
      background-color: $white;
    }
  }
}

.tribe-common--breakpoint-medium.tribe-common .tribe-common-g-row--gutters,
.tribe-common .tribe-common-g-row--gutters {
  margin-left: 0;
  margin-right: 0;
}

.tribe-common--breakpoint-medium.tribe-common .tribe-common-c-btn-border,
.tribe-common--breakpoint-medium.tribe-common a.tribe-common-c-btn-border,
.tribe-common .tribe-common-c-btn-border,
.tribe-common a.tribe-common-c-btn-border {
  border-radius: 0;
  background-color: $tertiary_color;
  border: solid 1px $tertiary_color;
  color: $tertiary_color;

  &:hover,
  &:focus,
  &:active,
  &:focus-within {
    background-color: $tertiary_color !important;
    border: solid 1px $tertiary_color !important;
    color: $white !important;
  }
}

.tribe-events .tribe-events-c-subscribe-dropdown .tribe-events-c-subscribe-dropdown__list {
  border-radius: 0;
}

.tribe-common--breakpoint-medium.tribe-events .datepicker {
  table {
    thead {
      background: transparent;
    }

    tbody tr:nth-of-type(even) {
      background: transparent;
    }
  }
}

/* featured event */

.tribe-events-calendar-list__event-row--featured {
  .tribe-events-calendar-list__event {
    background-color: $white;
  }

  .tribe-events-calendar-list__event-date-tag-datetime:after {
    top: 0;
    background-color: $tertiary_color !important;
  }
}

/* pagination */
.tribe-common--breakpoint-medium.tribe-events .tribe-events-c-nav__next,
.tribe-common--breakpoint-medium.tribe-events .tribe-events-c-nav__prev {
  font-family: $secondary-font, sans-serif;
  color: $primary_color !important;
}

.tribe-events .tribe-events-calendar-list-nav {
  border-top: 2px solid $tertiary_color;
}

/* export event */
.tribe-events .tribe-events-c-ical__link {
  align-items: center;
  background-color: $tertiary_color;
  border: 1px solid $tertiary_color;
  border-radius: 0;
  color: $tertiary_color;
  font-family: $secondary-bold-font, sans-serif;

  &:hover {
    background-color: $tertiary_color;
    border: 1px solid $tertiary_color;
  }
}

.tribe-common .tribe-common-c-svgicon {
  color: $tertiary_color;
}

.tribe-common .tribe-common-c-btn-icon {
  &.tribe-common-c-btn-icon--caret-right.tribe-events-c-top-bar__nav-link.tribe-events-c-top-bar__nav-link--next {
    svg {
      opacity: 0;
    }

    position: relative;

    &:before {
      font-family: $fontawesome;
      content: "\f30b";
      vertical-align: middle;
      font-weight: 900;
      opacity: 1 !important;
      background-color: transparent !important;
      position: absolute !important;
      transform: translate(-50%, -50%) !important;
      top: 50%;
      left: 50%;
    }
  }

  &.tribe-common-c-btn-icon--caret-left.tribe-events-c-top-bar__nav-link.tribe-events-c-top-bar__nav-link--prev {
    svg {
      opacity: 0;
    }

    position: relative;

    &:before {
      font-family: $fontawesome;
      content: "\f30a";
      vertical-align: middle;
      font-weight: 900;
      opacity: 1 !important;
      background-color: transparent !important;
      position: absolute !important;
      transform: translate(-50%, -50%) !important;
      top: 50%;
      left: 50%;
    }
  }
}

.tribe-common--breakpoint-medium.tribe-events .tribe-events-c-nav__prev {
  svg {
    opacity: 0 !important;
  }

  &:hover {
    svg {
      opacity: 0;
    }
  }

  position: relative;

  &:before {
    font-family: $fontawesome;
    content: "\f30a";
    vertical-align: middle;
    font-weight: 900;
    opacity: 1 !important;
    background-color: transparent !important;
    position: absolute !important;
    transform: translate(-50%, -50%) !important;
    top: 42%;
    left: 8px;
  }
}

ul.tribe-events-sub-nav {
  padding-left: 0 !important;

  .tribe-events-nav-previous {
    flex-basis: 50%;
    position: relative;
    padding-left: 20px;

    a {
      color: $tertiary_color !important;
      font-family: $secondary-font, sans-serif;

      &:before {
        display: none !important;
      }

      span {
        display: none;
      }
    }

    &:before {
      font-family: $fontawesome;
      content: "\f30a";
      vertical-align: middle;
      font-weight: 900;
      opacity: 1 !important;
      background-color: transparent !important;
      position: absolute !important;
      transform: translate(-50%, -50%) !important;
      top: 45%;
      left: 8px;
    }
  }

  .tribe-events-nav-next {
    flex-basis: 50%;
    position: relative;
    padding-right: 20px;
    text-align: right;

    a {
      color: $tertiary_color !important;
      font-family: $secondary-font, sans-serif;

      &:after {
        display: none !important;
      }

      span {
        display: none;
      }
    }

    &:before {
      font-family: $fontawesome;
      content: "\f30b";
      vertical-align: middle;
      font-weight: 900;
      opacity: 1 !important;
      background-color: transparent !important;
      position: absolute !important;
      transform: translate(-50%, -50%) !important;
      top: 45%;
      right: -3px;
    }
  }
}

.tribe-common--breakpoint-medium.tribe-events .tribe-events-c-nav__next {
  svg {
    opacity: 0 !important;
  }

  &:hover {
    svg {
      opacity: 0;
    }
  }

  position: relative;

  &:before {
    font-family: $fontawesome;
    content: "\f30b";
    vertical-align: middle;
    font-weight: 900;
    opacity: 1 !important;
    background-color: transparent !important;
    position: absolute !important;
    transform: translate(-50%, -50%) !important;
    top: 42%;
    right: -7px;
  }
}

button {

  &.tribe-events-c-nav__next.tribe-common-b2.tribe-common-b1--min-medium,
  &.tribe-events-c-nav__prev.tribe-common-b2.tribe-common-b1--min-medium {
    opacity: .4;
  }
}

.tribe-block__events-link .tribe-block__btn--link a {
  background-color: $tertiary_color;
  border: 1px solid $tertiary_color;
  color: $white;
}

.tribe-events-event-meta,
.tribe-events-related-events-title,
.tribe-events-single ul.tribe-related-events li,
.tribe-events-single ul.tribe-related-events li .tribe-related-events-title,
.tribe-events-cal-links,
.tribe-events-cal-links .tribe-events-gcal,
.tribe-events-cal-links .tribe-events-ical {
  font-family: $secondary-font, sans-serif;
  font-size: 16px;
}

.tribe-events-single ul.tribe-related-events li .tribe-related-events-title a {
  font-family: $secondary-bold-font, sans-serif;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.tribe-events-meta-group {
  flex: 1;

  a {
    &:hover {
      text-decoration: none;
      color: $tertiary_color;
    }
  }
}

.tribe-common--breakpoint-medium.tribe-events .tribe-events-calendar-month__day:hover:after {
  background-color: $tertiary_color;
}

.tribe-common .tribe-common-form-control-checkbox,
.tribe-common .tribe-common-form-control-radio {
  align-items: inherit;
  display: inherit;
}

.tribe-events .tribe-events-calendar-month__day--past .tribe-events-calendar-month__calendar-event {
  opacity: 1;
}

.tribe-events .tribe-events-calendar-month__calendar-event--featured:before {
  background-color: $tertiary_color;
}

.tribe-events-venue-map {
  width: 50%;
  order: 1 !important;
}

.tribe-events-event-meta.primary,
.tribe-events-event-meta.secondary {
  width: 100%;
}

.single-tribe_events .tribe-events-cal-links a:hover,
.tribe-events-cal-links a:hover {
  color: $tertiary_color;
  text-decoration: underline;
}

.tribe-events .datepicker .day.active,
.tribe-events .datepicker .day.active.focused,
.tribe-events .datepicker .day.active:focus,
.tribe-events .datepicker .day.active:hover,
.tribe-events .datepicker .month.active,
.tribe-events .datepicker .month.active.focused,
.tribe-events .datepicker .month.active:focus,
.tribe-events .datepicker .month.active:hover,
.tribe-events .datepicker .year.active,
.tribe-events .datepicker .year.active.focused,
.tribe-events .datepicker .year.active:focus,
.tribe-events .datepicker .year.active:hover,
.tribe-events .tribe-events-calendar-month__day-cell--selected,
.tribe-events .tribe-events-calendar-month__day-cell--selected:focus,
.tribe-events .tribe-events-calendar-month__day-cell--selected:hover {
  background: $tertiary_color !important;
}

.tribe-events em.tribe-events-calendar-month__mobile-events-icon--event {
  background-color: $tertiary_color;
}

html body .tribe-common table thead,
html body .tribe-common tr:nth-of-type(even) {
  background: none !important;
}

.tribe-events .tribe-events-calendar-month-mobile-events__mobile-event-title-link {
  font-family: $secondary-bold-font;
}

.tribe-events .tribe-events-calendar-month__day-cell--selected .echo {
  color: $white !important;
}

.tribe-events .tribe-events-calendar-month__multiday-event-bar-inner {
  background-color: $white !important;
}

.tribe-events-calendar-list__event-description.tribe-common-b2.tribe-common-a11y-hidden a {
  text-decoration: underline;
  font-family: $secondary-bold-font;

  &:hover {
    text-decoration: none;
  }
}

/**
* Tribe Month, Day and List Overrides
* Tribe Events Style Overrides
**/
.tribe-events {

  .tribe-events-l-container {
    padding-top: rem-calc(24) !important;

    .wf-block {
      margin-bottom: rem-calc(46);
    }

    .tribe-events-c-events-bar--border,
    .tribe-common-c-btn-border-small {
      border: 1px solid $tertiary_color;
    }

    .tribe-events-c-search__input-group {
      input {
        font-size: rem-calc(18);
      }
    }

    .tribe-events-c-top-bar__today-button,
    .tribe-events-c-events-bar__filter-button-text,
    .tribe-events-c-search__button,
    .tribe-events-c-view-selector__list-item-link .tribe-events-c-view-selector__list-item-text,
    .tribe-events-c-messages__message-list-item,
    .tribe-events-c-nav__list-item a,
    .tribe-filter-bar-c-pill__pill-label {
      font-size: rem-calc(18);
    }

    .tribe-events-calendar-month__calendar-event-datetime,
    .tribe-events-calendar-month__calendar-event-title,
    .tribe-events-calendar-month__multiday-event-hidden-title {
      font-size: rem-calc(14);
    }
  }

}

// Meta Group Styles
.tribe-events-meta-group {

  h2 {
    font-size: rem-calc(18) !important;
  }

  // .tribe-events-start-date
  abbr[title] {
    cursor: text;
    text-decoration: none !important;
  }

  &.tribe-events-meta-group-organizer {
    padding-top: rem-calc(64);

    h2 {
      font-family: "SofiaProRegular", sans-serif !important;
      font-size: rem-calc(16) !important;
      font-weight: bold !important;
      text-transform: none;

      &::after {
        display: inline-block;
        content: ":";
      }
    }
  }
}
