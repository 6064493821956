
ul.recent-news-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
 li {
    background-color: #E6E9ED;
    padding: 15px;
    margin-bottom: 20px;
    @media only screen and (max-width: 768px) {
        padding: 30px 15px;
    }
    .recent-news-img {
        width: 126px;
        float: left;
        margin-right: 20px;
        @media only screen and (max-width: 768px) {
            float: none;
            margin-bottom: 10px;
        }
        a.img-hover-zoom-link {
            .img-hover-zoom {
                overflow: hidden;
                img {
                    transition: transform .5s ease;
                    max-width: 100%;
                    vertical-align: middle;
                  }
                  &:hover img {
                    transform: scale(1.2);
                  }
            }
        }
    }
    .recent-news-content {
        margin-left: 146px;
        &.recent-news-content-img-none {
            margin-left: 0;
        }
        @media only screen and (max-width: 768px) {
            margin-left: 0;
        }
        .recent-news-date {
            font-size: 16px;
            margin-bottom: 6px;
        }
        .recent-news-title {
            margin: 0;
            line-height: 24px;
            a {
                font-size: 24px;
                color: $defaultblue;
                text-decoration: none;
                font-family: $primary-bold-font;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        p {
            font-size: 16px;
        }
    }
    &:hover, &:focus-within {
        outline: solid 3px $red;
        .recent-news-img {
            a.img-hover-zoom-link {
                .img-hover-zoom {
                    img {
                        transition: transform .5s ease;
                      }
                      img {
                        transform: scale(1.2);
                      }
                }
            }
        }
        .recent-news-content {
            .recent-news-date {
                font-size: 16px;
            }
            .recent-news-title {
                a {
                    text-decoration: underline;
                }
            }
            p {
                font-size: 16px;
            }
        }
    }
 }
 &.recent-news-list-minimal {
        li {
            padding: 10px;
            background-color: transparent;
            &:hover, &:focus-within {
                outline: none;
            }
        }
        .recent-news-content {
            margin-left: 0;
            .recent-news-title {
                font-size: 18px;
            }
        }
    }
}

.news-listing-cards {
    ul.recent-news-list {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        @media only screen and (max-width: 768px) {
            display: block;
        }
        li {
            flex: 1 0 48%;
            margin-bottom: 0;
            @media only screen and (max-width: 769px) {
                margin-bottom: 20px;
            }
        }
    }
}

.news-events-description-max-width {
    max-width: 593px;
    margin: 0 auto 30px;
    font-size: 18px;
}
.new-events-list-max-width {
    max-width: 1168px;
    margin-bottom: 48px;
}

.news-events-cta-btns-max-width {
    max-width: 915px;
    @media only screen and (min-width: 769px) {
        margin-bottom: 132px;
    }
    
}

.featured-news-post-wrap {
    @media only screen and (max-width: 992px) {
        &.secondaryblue-bg.p-5 {
            padding: 17px 13px !important;
        }
    }
    
    img {
        @media only screen and (max-width: 992px) {
            margin-bottom: 15px;
        }
    }
    .featured-news-post-date {
        font-size: 14px;
    }
    h2 {
        line-height: 38px;
        margin-top: 8px;
        a {
            color: $defaultblue;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    p.lead {
        font-size: 16px;
        margin-bottom: 0;
        line-height: 28px;
    }
    .btn.btn-link {
        font-size: 16px;
        text-transform: inherit;
        color: #222222;
        font-variation-settings: "wght" 400;
    }
}

.sidebar-offset {
    ul.recent-news-list li .recent-news-content .recent-news-title a {
        font-size: 18px;
    }
}

.news-events-front-page-section {
    h1, h2, h3, h4, h5, h6 {
        &.news-events-front-page-heading {
            font-family: $primary-bold-font;
            font-size: 55px;
        }
    }
    .news-events-front-page-heading {
        font-family: $primary-bold-font;
        font-size: 55px;
        color: $defaultblue;
        margin-bottom: 0.5rem;
    }

    h1, h2, h3, h4, h5, h6 {
        &.news-events-front-page-news-heading, &.news-events-front-page-events-heading {
            font-size: 24px;
            font-family: $primary-bold-font;
            color: $defaultblue;
        }
    }
    .news-events-front-page-news-heading, .news-events-front-page-events-heading {
        font-size: 24px;
        font-family: $primary-bold-font;
        color: $defaultblue;
        margin-bottom: 0.5rem;
    }
}

