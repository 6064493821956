
.our-programs-container {
    @media only screen and (min-width: 769px) {
        padding: 60px;
    }
    .our-programs-list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        li {
            flex: 1 0 21%;
            margin: 5px;
            @media only screen and (max-width: 768px) {
                flex: 1 0 42%;
            }
            .our-programs-title {
                line-height: 20px;
                a {
                    font-family: $primary-bold-font;
                    font-size: 20px;
                    color: $defaultblue;
                    text-decoration: none;
                    &:hover, &:focus {
                        text-decoration: underline;
                    }
                }
            }
            a.img-hover-zoom-link {
                .img-hover-zoom {
                    overflow: hidden;
                    margin-bottom: 6px;
                    box-shadow: 0 1px 1px rgb(0 0 0 / 15%), 0 2px 2px rgb(0 0 0 / 15%), 0 4px 4px rgb(0 0 0 / 15%), 0 8px 8px rgb(0 0 0 / 15%);
                    img {
                        transition: transform .5s ease;
                        max-width: inherit;
                        width: 100%;
                        vertical-align: middle;
                    }
                }
                &:hover, &:focus {
                    .img-hover-zoom {
                        outline: solid 4px $red;
                    }
                }
            }
            &:hover, &:focus-within {
                .our-programs-title {
                    a {
                        text-decoration: underline;
                    }
                }
                a.img-hover-zoom-link {
                    .img-hover-zoom {
                        outline: solid 5px $red;
                    }
                }
            }
        }
    }
}