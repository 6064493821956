.tabs-container {
  background-color: transparent !important;
  .nav-tabs {
    border-bottom: 0 none;
    padding-top: 0 !important;
    padding-left: 0 !important;
   }
  .nav-tabs .nav-item {
    margin-bottom: 0;
    margin-right: 1.188rem;

    &:last-child{
      margin-right: 0;
    }
  }
  .nav-tabs .nav-link {
    position: relative;
    border: 1px solid transparent;
    text-decoration: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
  }
  .nav-tabs .nav-link.disabled {
    color: $off-black;
    background-color: transparent;
    border-color: transparent;
  }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: $off-black;
    background-color: $secondary_color;
    border-color: $secondary_color !important;

    &:hover{
      background-color: $secondary_color !important;
      border-color: $secondary_color !important;
      text-decoration: none !important;
    }

    &:after{
      content:"";
      display: block;
      position: absolute;
      top: 100%;
      border-top: 10px solid $secondary_color;
      border-right: 10px solid transparent;
      border-bottom: 0;
      border-left: 10px solid transparent;
    }
  }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .nav-tabs, .staff-directory-page .nav-tabs {
    padding-top: 50px;
    padding-left: 30px;
  }
  .nav-tabs .nav-link {
    background-image: none;
    background-color: $gray;
    border: 1px solid $shadow-gray !important;
    border-bottom: 0 none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0 20px;
    font-family: 'Arial';
    font-size: 16px;
    font-weight: 400;
    color: $off-black;
    width: 180px;
    height: 71px;
  }
  .nav-tabs .nav-link:hover {
    background-image: none;
    background-color: $white;
    border-color: $tertiary_color !important;
    text-decoration: underline !important;
    color: $tertiary_color;
  }
  @media (max-width: 768px) {
    .nav-tabs .nav-link {
      display: none;
    }
  }
  .nav-tabs .nav-link.active {
    background-image: none;
    color: $tertiary_color;
  }
  @media (max-width: 768px) {
    .nav-tabs .nav-link.active {
      display: inline-block;
      width: 100%;
      height: 80px;
      text-align: center;
      padding-top: 40px;
    }
  }
  .nav-tabs .nav-link.active:hover {
    background-image: none;
  }
  @media (max-width: 768px) {
    .nav-tabs .nav-item {
      width: calc(100% - 40px);
    }
  }
  .tab-content {
    background: #fff;
    margin: 0;
    padding: 54px 40px 20px 40px;
    border-top: 0 none !important;

    @include media-breakpoint-down(sm) {
      padding: 0 0 0 0;
      background: transparent;
    }

    .panel-body{
      @include media-breakpoint-down(sm) {
        padding: 35px 14px 69px 14px;
        background: $white;
      } 
    }

    .tab-pane{
      @include media-breakpoint-down(sm) {
        margin-bottom: 12px;
      }
    }

    .panel{
      @include media-breakpoint-down(sm) {
        border: 0 none;
      }
      .panel-title{
        @include media-breakpoint-down(sm) {
          padding: 0 0 !important;
        }
        a{
          @include media-breakpoint-down(sm) {
            position: relative;
            text-align: center;
            background-color: $secondary_color;
            font-family: 'Arial';
            font-size: 1rem;
            font-weight: 400;
            color: $off-black;
            padding: 21px 0 21px !important;
            line-height: 1;
          }

          &.collapsed{
            @include media-breakpoint-down(sm) {
              border: 1px solid $shadow-gray;
              background-color: $gray;
            } 
            &:after{
              @include media-breakpoint-down(sm) {
                display: none;
              } 
            }
          }

          &:after{
            @include media-breakpoint-down(sm) {
              display: block;
              content: "";
              position: absolute;
              top: 100%;
              left: 50%;
              margin-left: -10px;
              border-top: 10px solid #F2B22B;
              border-right: 10px solid transparent;
              border-bottom: 0;
              border-left: 10px solid transparent;

            } 
          }
        }
      }
    }

    h3{
      color: $tertiary_color;
    }
  }
  @media (max-width: 1169px) {
    .tab-content {
      padding-top: 30px;
    }
  }
  .tab-content .row {
    padding: 0;
  }
  
  .tabs-darkBg {
    .nav-tabs .nav-link {
      color: #ffffff;
    }
    .nav-tabs .nav-link:hover {
      color: #272727;
    }
    .nav-tabs {
        border-bottom: 1px solid transparent;
    }
  }
  
  /* Bootstrap responsive tabs */
  
  
  
  .responsive-tabs-container[class*="accordion-"] .accordion-link {
    display: none;
  
  }
  
  
  
  @media (max-width: 767px) {
    .responsive-tabs-container.accordion-xs .nav-tabs {
      display: none;
    }
  
    .responsive-tabs-container.accordion-xs .accordion-link {
      display: block;
      font-family: 'montserrat-bold';
    }
  
  
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    .responsive-tabs-container.accordion-sm .nav-tabs {
      display: none;
    }
  
    .responsive-tabs-container.accordion-sm .accordion-link {
      display: block;
    }
  
  
  }
  
  @media (min-width: 992px) and (max-width: 1199px) {
    .responsive-tabs-container.accordion-md .nav-tabs {
      display: none;
    }
  
    .responsive-tabs-container.accordion-md .accordion-link {
      display: block;
    }
  }
  
  @media (min-width: 1200px) {
    .responsive-tabs-container.accordion-lg .nav-tabs {
      display: none;
    }
  
    .responsive-tabs-container.accordion-lg .accordion-link {
      display: block;
    }
  }
  }
  