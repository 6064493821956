.quick-links-list-wrap {
    background-color: $secondaryblue;
    padding: 18px 11px;
    margin-bottom: 24px;
    .quick-links-list-menu {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
            margin-bottom: 13px;
            a {
                text-decoration: underline;
                color: $lightblue;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}