// .table {
//   th {
//     color: $white;
//     background-color: $defaultblue;
//     border-color: transparent;
//     font-weight: normal;
//     padding: 7px !important;
//   }
//   a {
//     text-decoration: none;
//     &:hover {
//       text-decoration: underline;
//     }
//   }
//   tr {
//     border: none;
//     &:nth-child(odd) {
//       background: white;
//       border-bottom: 1px solid #BABABA;
//     }
//     &:nth-child(even) {
//       background: #E4E4E4;
//     }
//     td {
//       border-right: 1px solid #BABABA;
//       &:last-child {
//         border-right: none;
//       }
//     }
//     @media only screen and (min-width: 640px) {
//       &:hover {
//         background-color: $lightblue;
//         p {
//           color: $white;
//         }
//         a {
//           color: $white;
//           text-decoration: underline;
//         }
//       }
//     }
//   }
// }
.table{
  #myTable_filter{
    display: none !important;
  }
  th {
    color: $white;
    background-color: $defaultblue;
    border-color: transparent;
    font-weight: normal;
    padding: 7px !important;
    line-height: 35px;

    &.sorting_asc{
      &::before{
        opacity: 1 !important;
      }
    }
 
    &.sorting_desc{
      &::after{
        opacity: 1 !important;
      }
    }
  }
  tbody{
    tr{
      &.even{
        background: #E4E4E4 !important;
      }
      &.odd{
        background: #FFF !important;
      }
      &:hover{
        background:#30609A !important;
        color: $white;
        a{
          color:#FFF !important;
        }
      }
    }
  }

}

.dataTables_paginate{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .paginate_button{
    border-radius: 3px;
    border: solid 1px transparent;
    background-color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    color: #30609A;
    line-height: normal;
    text-decoration: none !important;
    transition: none;
    &.current{
      background-color: #001641 !important;
      border: solid 1px #001641 !important;
      &:hover{
        color:#FFF !important;
      }
     
    }
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current{
  color:#FFF !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover{
  color:#FFF !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover{
  background: #001641 !important;
  color: #ffffff;
}

.table-hover > tbody > tr:hover > * {
  background: #30609A;
  color:#FFF !important;
}

.table-striped > tbody > tr:nth-of-type(2n+1) > * {
  --bs-table-accent-bg: transparent;
}

