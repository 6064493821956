
.video-card {
    .video-caption {
        padding: 0;
        line-height: unset;
    }

    .captitle{        
        margin: 0 0;
        font-weight: 400;
    }

    .video-link{
        margin-top: 10px;
    }

    p{
        font-family: 'Arial';
        font-size: 16px;
        font-weight: 400;
    }
}

.image-caption {
    .card-body {
        padding: 15px 15px;
    }
}

.clear-caption {
    background: none !important;
    border: none !important;

    .card-body{
        padding: 10px 0 10px;
    }

    .video-link{
        margin-top: 0;
    }

    &.image-card{
        .card-body{
            padding: 15px 15px;
        }
    }
}

.image-card{
    &.border{
        border: 1px solid $shadow-gray;
        border-radius: 5px;
    }
    .title {
        color: #3264A6;
        font-size: 16px;
        line-height: 24px;
        font-family: $secondary-font;
    }
    p {
        margin-bottom: 0.5rem;
    }
}

figure,
figcaption {
    margin: 0;
    padding: 0;
    color: #222222;
}

.img-grow {
    position: relative;
    overflow:hidden;
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}