.wf-list-group-flush .list-group-item {
  padding: 9px 0;
  border: 1px solid #d1d3d3;
  border-right: 0;
  border-left: 0;
  border-top: 0;
  font-family: $secondary-bold-font, sans-serif;
  font-weight: 400;
  background-color: transparent;
  display: inline-block !important;
  width: 100%;
}

.list-group-default, .is-style-custom-list-style {
  line-height: 2.4;
  color: $gray-700;
  font-family: $secondary-font, sans-serif;
  font-size: 18px;
}

ul.list-group-default, ul.is-style-custom-list-style {
  list-style: none;

  li {
    &::before {
      content: "\2022";
      color: $tertiary_color;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }

    >ul {
      list-style: default;

      li {
        &::before {
          content: none;
        }
      }
    }
  }

}

ol.list-group-default, ol.is-style-custom-list-style {
  counter-reset: item;
  padding-left: 15px;

  li {
    display: block;

    &:before {
      content: counter(item) ". ";
      counter-increment: item;
      font-family: $secondary-bold-font, sans-serif;
    }
  }

  li>ol {
    list-style-type: lower-latin !important;

    li {
      list-style-type: lower-latin !important;
    }
  }
}

.wf-list-group-flush .list-group-item a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: $tertiary_color;
  }
}

.wf-gateway-resources.wf-list-group-flush .list-group-item a {
  text-decoration: underline;
  font-family: $secondary-bold-font, sans-serif;

  &:hover {
    color: $tertiary_color;
  }
}

.wf-list-group-flush.list-group-yellow .list-group-item a {
  color: #FFB71B;
  text-decoration: none;
}

.wf-list-group-flush.list-group-yellow .list-group-item a:hover {
  text-decoration: underline;
}

.wf-list-group-flush.list-group-border-none .list-group-item {
  border: 0;
  font-family: $secondary-font, sans-serif;
  font-weight: 400;
}

.wf-list-group-flush .list-group-item:last-child {
  border-bottom: 2px solid #d1d3d3;
}

.wf-list-group-flush.font-sofia .list-group-item {
  font-family: $secondary-font, sans-serif;
}

.wf-list-group-flush.list-group-border-none .list-group-item:last-child {
  border-bottom: 0;
}



@include mq('phablet-large', 'min') {
  ul.list-group-col-6 {
    columns: 2 !important;
    -webkit-columns: 2 !important;
    -moz-columns: 2 !important;
    display: inherit !important;
    webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px;
  }
}






.list-group-item {
  background-color: transparent;
}

.list-bullets {
  li {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      line-height: 2rem;
    }
  }
}

/* SOCIAL LINKS */
.wf-story-share {
  padding: 12px 15px 5px;

  @include mq('tablet') {
    text-align: center;
    width: 90px;
    border: none !important;
    position: fixed;
    top: 102px;
    right: 0;
    padding: 15px 0 0;
    z-index: 999;
  }

  label {
    @include mq('tablet') {
      font-size: 15px;
    }

    font-size: 20px;
    color: #0F2044;
    text-transform: uppercase;
    font-weight: bold;
  }

  .wf-story-share-social-links {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin-bottom: 15px;

      @include mq('tablet', 'max') {
        display: inline;
        margin-right: 20px;
        margin-bottom: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        font-size: 28px;
        color: #0F2044;
        text-decoration: none;
      }
    }
  }
}



.wp-block-faculty-quick-links p.fs-5 {
  font-size: 22px !important;
  margin-bottom: 0;

  @include mq('tablet-large', 'max') {
    font-size: 18px !important;
    line-height: 24px !important;
  }
}
