.group-list {
  padding-left: 1.875rem;
  list-style: none;
  list-style-type: none;

  li.list-style-circle {
    list-style-type: circle;
  }

  li{
    line-height: 1.3;
    margin-bottom: 0.938rem;
    color: $off-black;
  }

  ol,ul{
    padding-left: 1.25rem;
  }

  &.ul{
    li {
      display: list-item !important;
      list-style: outside;
    }
  }
  &ol {
    list-style-type: decimal;
    list-style-type: decimal;
    li {
      display: list-item !important;
      list-style: outside;
    }
  }

  &.text-white{
    li{
      color: $white;

      &:before{
        background-color: $white;
      }
    }
  }
}

.group-list.flex-list {
  li, ol {
    display: inline-flex;
  }
}

.group-list {
  li {
    margin-bottom: 15px;
  }
}

ul.group-list{
  &>li::marker{
    content: '';
  }

  &>li{
    position: relative;

    &:before{
      content: '';
      display: block;
      position: absolute;
      left: -15px;
      top: 6px;
      z-index: 1;
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background-color: $tertiary_color;
    }

    &.bullet-diamond{
      &::marker{
        display: none;
        content: '';
      }
      &:before{
        content: '';
        display: block;
        position: absolute;
        left: -15px;
        top: 6px;
        z-index: 1;
        width: 5px;
        height: 5px;
        border-radius: 0;
        transform: rotate(45deg);
        background-color: $tertiary_color;
      }
    }
  }

  ul{
    &>li::marker{
      color: $tertiary_color;
    }
  }

  &.red{
    &>li:before{
      background-color: #D73882;
    }

    li.bullet-diamond{
      list-style-type: none;
      &::marker{
        display: none;
      }
      &:before{
        background-color: #D73882;
      }
    }

    &.bullet-diamond{
      &>li{
        list-style-type: none;
        &:before{
          background-color: #D73882;
        } 
      }
    }
  }

  &.bullet-diamond{
    &>li::marker{
      content: '';
      display: block;
      width: 11px;
      height: 11px;
      transform: rotate(90deg);
    }
  }
}

ul.check-bullet {
  > li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  &:before {
    content: "\f00c";
    font-family: FontAwesome;
    padding: 0 3px 0 0;
    list-style-type: none;
    color: $primary_color;
  }
}

ul.arrow-bullet {
  > li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  &:before {
    content: "\f0a9";
    font-family: FontAwesome;
    padding: 0 10px 0 0;
    list-style-type: none;
    color: #023188;
  }
  }
}

ul.ban-bullet {
  > li {
    list-style-type: none;
    margin: 0;
    padding: 0;

  &:before {
    content: "\f05e";
    font-family: FontAwesome;
    padding: 0 10px 0 0;
    list-style-type: none;
    color: $secondary_color;
  }
}
}

ul.blue-bullet {
 >  li {
    position: relative;
    padding: 0 0 0 26px;
    list-style-type: none;

    &:before {
    content: counter(li);
    counter-increment: li;
    height: 9px;
    width: 9px;
    border: 1px solid #fff;
    border-radius: 50%;
    color: transparent;
    text-align: center;
    position: absolute;
    left: 3px;
    top: 7px;
    font-family: "Gotham Bold";
    font-weight: 400;
    font-size: 7px;
    background-color: #023188;
    box-shadow: 0 0px 0px 1px #023188;
    }
  }
}

ul.yellow-bullet {
  >  li {
    position: relative;
    padding: 0 0 0 26px;
    list-style-type: none;

    &:before {
    content: counter(li);
    counter-increment: li;
    height: 9px;
    width: 9px;
    border: 1px solid #fff;
    border-radius: 50%;
    color: transparent;
    text-align: center;
    position: absolute;
    left: 3px;
    top: 7px;
    font-family: "Gotham Bold";
    font-weight: 400;
    font-size: 7px;
    background-color: #fff;
    box-shadow: 0 0px 0px 2px $secondary_color;
    }
  }
}

ul.simple-group-list {
  counter-reset: li;
  list-style-type: none;
  padding-left: 10px;

  li.yellow-bullet {
    position: relative;
    padding: 0 0 0 26px;
    list-style-type: none;

    &:before {
    content: counter(li);
    counter-increment: li;
    height: 9px;
    width: 9px;
    border: 1px solid #fff;
    border-radius: 50%;
    color: transparent;
    text-align: center;
    position: absolute;
    left: 3px;
    top: 7px;
    font-family: "Gotham Bold";
    font-weight: 400;
    font-size: 7px;
    background-color: #fff;
    box-shadow: 0 0px 0px 2px $secondary_color;
    }
  }





  li.blue-bullet {
    position: relative;
    padding: 0 0 0 26px;
    list-style-type: none;

    &:before {
    content: counter(li);
    counter-increment: li;
    height: 9px;
    width: 9px;
    border: 1px solid #fff;
    border-radius: 50%;
    color: transparent;
    text-align: center;
    position: absolute;
    left: 3px;
    top: 7px;
    font-family: "Gotham Bold";
    font-weight: 400;
    font-size: 7px;
    background-color: #023188;
    box-shadow: 0 0px 0px 1px #023188;
    }
  }
}




ol.group-list{
  list-style: decimal;
  list-style-type: decimal;
  li::marker {
    font-weight: 700;
    color: $off-black;
  }

  ol,ul{
    padding-left: 1.25rem;
  }
}

ol.upper-alpha{
  list-style-type: upper-alpha;
}
ol.lower-alpha{
  list-style-type: lower-alpha;
}
ol.decimal-leading-zero{
  list-style-type: decimal-leading-zero;
}
ol.lower-roman{
  list-style-type: lower-roman;
}
ol.upper-roman{
  list-style-type: upper-roman;
}