/**
* === Alphabetical Pagination ===
* A line of letters from A to Z with links
*/
.alphabetic-pagination {
    @include mq('tablet', 'max') {
        display: none;
    }
    ul.pagination {
        li.page-item {
            flex: 1;
            line-height: normal;
            a.page-link {
                font-size: 16px;
                padding:0;
                color: $gray-700;
                background-color: transparent;
                border: none;
                border-right: 1px solid $gray-700;
                text-align: center;
                font-family: $secondary-bold-font, sans-serif;
            }
            &:first-child, &:last-child {
                a.page-link {
                    border-radius: 0;
                }
            }
            &:last-child {
                a.page-link {
                    border-right: none;
                }
            }
        }
    }
}