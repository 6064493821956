/**
 * Horizontal Rule Block
 */

 hr.primary {
    border-top: 1px solid $defaultblue;
    opacity: 1;
}

hr.secondary {
    border-top: 1px solid $secondary_color;
    opacity: 1;
}

hr.tertiary {
    border-top: 1px solid $tertiary_color;
    opacity: 1;
}

hr.black {
    border-top: 1px solid $black;
    opacity: 1;
}