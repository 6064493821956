.feed-item-collapsed {
  background-color: $white;
  border: solid 1px #B8B8B8;
  color: #003087;
  padding: 0;
  width: 39px;
  height: 39px;
  &:before {
    float: right !important;
    font-family: FontAwesome;
    content:"\f068";
    padding: 8px 13px;
  }
  &.collapsed {
    &:before {
      float: right !important;
      content:"\f067";
      font-family: FontAwesome;
    }
  }
}

.feed-item-collapsed-hide {
  display: none;
}


.calendar-event {
  margin: 0 0 30px 0;
  padding: 0;
  &:nth-child(-n+3) {
    display: none;
    @media (min-width: 640px) {
      display: inline-block;
    }
  }
  .hexagon-wrap {
    width: 85px;
    display: inline-block;
    vertical-align: top;
    @media (max-width: 1169px) {
      display: block;
      margin: auto;
      padding-bottom: 10px;
    }
  }
  .hexagon {
    position: relative;
    width: 69px;
    height: 78px;
    background: url("/img/hexagon.svg") no-repeat;
    margin: 0;
    color: $tertiary_color;
    background-size: cover;
    // @include media-breakpoint-down(md){
    // }
    .date {
      width: 100%;
      line-height: 20px;
      font-family: "Gotham Bold";
      padding: 17px 0;
      span {
        text-align: center;
        display: inline-block;
        width: 100%;
      }
      .month {
        font-size: 15px;
      }
      .day {
        font-size: 26px;
      }
    }
  }
  .details {
    display: inline-block;
    line-height: 25px;
    min-height: 150px;
    @include media-breakpoint-down(lg){
      padding-right: 15px;
    }
    @include media-breakpoint-down(md){
      padding:0 20px;
    }
    @include media-breakpoint-down(sm){
      padding: 0 0 0 20px;
    }
    .event-title,
    .location,
    .time {
      margin: 0;
      padding: 0;
    }
    .event-title a {
      font-size: 18px;
      color: $tertiary_color;
      font-weight: bold;
      font-family: "Gotham Bold";
      line-height: 22px;
      display: inline-block;
    }
    .location {
      font-family: "Gotham Medium";
    }
    .time {
      font-family: "Gotham Book";
    }
  }
  &.one-off {
    @include media-breakpoint-down(sm){
      display: block;
    }
  }
}

.feed-post {
  h4{
    a{
      font-family: inherit;
    }
  }
  .post-date {
    padding: 9px 14px;
    background-color: $shadow-gray;
    display: inline-block;
    font-family: 'Arial';
    font-size: 16px;
    color: $off-black;
    border-radius: 5px;
  }
  a {
    color: $primary_color;
    font-family: 'Arial';
    text-decoration: underline;

    &:hover{
      color: $tertiary_color;
    }
  }
  .feed-excerpt {
    font-size: 16px;    
  }
  .Aligner-item {
    width: 100%;
  }
  @include media-breakpoint-down(sm){
    border-bottom: 0 none;
    padding-bottom: 20px;
  }
}

.spotlight {
  figcaption {
    padding: 10px;
  }
}

.degree-card {
  .card-body:first-child {
    // padding: 0 0 20px 0;
    border-bottom: 2px solid #707070;
  }
}