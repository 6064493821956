.table .thead-dark th {
    color: $white;
    background-color: $tertiary_color;
    border-color: transparent;
    font-weight: normal;
}

.table .thead-dark {
  border: solid 3px $tertiary_color;
}

.table {
  a {
    text-decoration: underline;
  }

  tr{
    &.spacer{
      border-bottom: 0 none;
    }
  }
}

.table .thead-liteBlue th {
    color: $tertiary_color;
    background-color: $shadow-gray;
    border-color: transparent;
    font-weight: normal;
}

.table .thead-liteBlue {
  border: solid 3px $shadow-gray;
}

.table {
  a {
    text-decoration: underline;
  }
}
