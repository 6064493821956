/**
* === Custom Spacing ===
* In various dropdowns you can add custom spacing options.
* Edit them here in this file.
*/

// ===================
// Custom Margins
// ===================

.m-custom-1 {
    margin: 50px !important;
}

.m-custom-2 {
    margin: 100px !important;
}

.m-custom-3 {
    margin: 150px !important;
}

// ===================
// Custom Vertical Margins
// ===================
$my-custom-1: 50px;
.my-custom-1 {
    margin-top: $my-custom-1 !important;
    margin-bottom: $my-custom-1 !important;
}
$my-custom-2: 100px;
.my-custom-2 {
    margin-top: $my-custom-2 !important;
    margin-bottom: $my-custom-2 !important;
}
$my-custom-3: 150px;
.my-custom-3 {
    margin-top: $my-custom-3 !important;
    margin-bottom: $my-custom-3 !important;
}

// ===================
// Custom Horizontal Margins
// ===================
$mx-custom-1: 50px;
.mx-custom-1 {
    margin-left: $mx-custom-1 !important;
    margin-right: $mx-custom-1 !important;
}
$mx-custom-2: 100px;
.mx-custom-2 {
    margin-left: $mx-custom-2 !important;
    margin-right: $mx-custom-2 !important;
}
$mx-custom-3: 150px;
.mx-custom-3 {
    margin-left: $mx-custom-3 !important;
    margin-right: $mx-custom-3 !important;
}

// ===================
// Custom Padding
// ===================

.p-custom-1 {
    padding: 50px !important;
}

.p-custom-2 {
    padding: 100px !important;
}

.p-custom-3 {
    padding: 150px !important;
}

// ===================
// Custom Vertical Padding
// ===================
$py-custom-1: 50px;
.py-custom-1 {
    padding-top: $py-custom-1 !important;
    padding-bottom: $py-custom-1 !important;
}
$py-custom-2: 100px;
.py-custom-2 {
    padding-top: $py-custom-2 !important;
    padding-bottom: $py-custom-2 !important;
}
$py-custom-3: 150px;
.py-custom-3 {
    padding-top: $py-custom-3 !important;
    padding-bottom: $py-custom-3 !important;
}

// ===================
// Custom Horizontal Padding
// ===================
$px-custom-1: 50px;
.px-custom-1 {
    padding-left: $px-custom-1 !important;
    padding-right: $px-custom-1 !important;
}
$px-custom-2: 100px;
.px-custom-2 {
    padding-left: $px-custom-2 !important;
    padding-right: $px-custom-2 !important;
}
$px-custom-3: 150px;
.px-custom-3 {
    padding-left: $px-custom-3 !important;
    padding-right: $px-custom-3 !important;
}