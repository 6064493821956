
.quotes-wrap {
  background-color: $defaultblue;
  color: $white;
  padding: 35px 132px;
  border: solid 3px $red;
  text-align: center;
  font-size: 18px;
  position: relative;
  margin-bottom: 75px;
  p {
    color: $white;
  }
  @media only screen and (max-width: 768px) {
      padding: 83px 15px 32px;
      font-size: 24px;
  }
  &::before {
      content: "";
      position: absolute;
      top: 37px;
      left: 28px;
      margin-left: -4px;
      margin-right: -4px;
      height: 53px;
      width: 56px;
      background-size: contain;
      background-image: url(../../images/quote-open.svg);
      @media only screen and (max-width: 768px) {
          height: 39px;
          width: 42px;
          top: 32px;
          left: 50%;
          transform: translate(-25%, 0);
      }
  }
  &::after {
      content: "";
      position: absolute;
      bottom: 84px;
      right: 28px;
      margin-left: -4px;
      margin-right: -4px;
      height: 53px;
      width: 56px;
      background-size: contain;
      background-image: url(../../images/quote-close.svg);
      @media only screen and (max-width: 768px) {
          display: none;
      }
  }
  .quoter {
      text-transform: uppercase;
      font-variation-settings: 'wght' 700;
      margin: 0;
      font-size: 18px;
  }
  .quoter-title {
      font-size: 18px;
      margin-bottom: 0;
  }
}
blockquote {
  font-family: $primary-bold-font;
  font-size: 32px;
  .quote {
      display: block;
      margin-bottom: 16px;
      line-height: 38px;
  }
  .blockquote-name {
      display: block;
      font-size: 18px;
      font-family: $secondary-font, sans-serif;
      font-variation-settings: 'wght' 700;
      margin-bottom: 5px;
      line-height: normal;
  }
  .blockquote-program {
      display: block;
      font-size: 18px;
      font-family: $secondary-font, sans-serif;
      font-variation-settings: 'wght' 400;
      line-height: normal;
  }
}