.page-anchors{
  position: relative;
  border: 1px solid $shadow-gray;
  &.show{
    .links{
      display: block;
    }

    .page-anchor-toggle{
      background-color: $tertiary_color;
      .fa-caret-down{
        display: none;
      }

      .fa-caret-up{
        display: inline;
      }
    }
  }
  .page-anchor-toggle{
    display: block;
    position: relative;
    padding: 16px 51px 16px 14px;
    background-color: $tertiary_color;
    font-family: 'montserrat';
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
    color: $white;
    text-decoration: none !important;

    i{
      position: absolute;
      right: 15px;
      top: 50%;
      margin-top: -12px;
      font-size: 24px;
      display: none;

      @media (max-width: 991px) {
        display: block;
      }

      &.fa-caret-down{
        display: none;
        @media (max-width: 991px) {
          display: block;
        }
      }

      &.fa-caret-up{
        display: none;
      }
    }
  }

  .links{
    padding: 20px 16px;
    background-color: $white;
    @media (max-width: 991px) {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 2;
      border: 1px solid $shadow-gray;
    }
  }
}