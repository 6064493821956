/*
* Fade in Image
  How to use:

  div {
    @include fadeInImage();
  }

*/
@keyframes fadeInImage {
    0% {
      opacity: 0.2;
      transform: scale(1.5);
    }
  
    100% {
      opacity: 1;
      transform: none;
    }
  }