
.sub-menu-horizontal {
    background: #042D61;
    border-radius: 5px;
    margin: 4px 0 2px 0;
    width: 100%;
    display: inline-grid;
    @media screen and (min-width: 768px) {
        grid-auto-flow: column;
    }
    .btn {
        font-size: 14px;
        line-height: 21px;
        color: white;
        text-transform: capitalize;
        font-weight: 100;
        font-family: $secondary-font;
        &.active {
            text-decoration: underline;
            text-decoration-color: #FFCE00;
            text-underline-offset: 5px;
            text-decoration-thickness: 2px;
        }
    }
}