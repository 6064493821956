
ul.resp-tabs-list,
.parentVerticalTab p {
  margin: 0px;
  padding: 0px;
}

.resp-tabs-list li {
  font-weight: 600;
  font-size: 16px;
  display: inline-block;
  padding: 13px 15px;
  list-style: none;
  cursor: pointer;
  float: left;
  color: $off-black;
}

.resp-tabs-list li:hover {
  background-color: transparent !important;
}

.resp-tabs-container {
  padding: 0px;
  background-color: #fff;
  clear: left;
}

h2.resp-accordion {
  cursor: pointer;
  padding: 5px;
  display: none;
  min-height: 40px;
}


.resp-tab-content {
  display: none;
  padding: 0 15px;
}

.resp-tab-active {
  border: 1px solid #5AB1D0 !important;
  border-bottom: none;
  margin-bottom: -1px !important;
  padding: 12px 14px 14px 14px !important;
  border-top: 4px solid #5AB1D0 !important;
  border-bottom: 0px #fff solid !important;
}

.resp-tab-active {
  border-bottom: none;
  background-color: #fff;
}

.resp-content-active,
.resp-accordion-active {
  display: block;
}

.resp-tab-content {
  border: 1px solid #c1c1c1;
  border-top-color: #5AB1D0;
}

h2.resp-accordion {
  font-size: 16px;
  border: 1px solid #c1c1c1;
  border-top: 0px solid #c1c1c1;
  margin: 0px;
  padding: 10px 15px;
}

h2.resp-tab-active {
  border-bottom: 0px solid #c1c1c1 !important;
  margin-bottom: 0px !important;
  padding: 10px 15px !important;
}

h2.resp-tab-title:last-child {
  border-bottom: 12px solid #c1c1c1 !important;
  background: blue;
}

/*-----------Vertical tabs-----------*/
.resp-vtabs ul.resp-tabs-list {
  float: left;
  width: 20%;
}

.resp-vtabs .resp-tabs-list li {
  display: block;
  padding: 32px 26px 32px 0 !important;
  margin: 0 0 0;
  cursor: pointer;
  float: none;

  @include media-breakpoint-down(sm) {
    padding: 0 15px 0 15px !important;
    vertical-align: middle;
    max-width: 115px;
    white-space: break-spaces;
  }

  &:hover{
    background-color: transparent;
  }
}

.resp-vtabs .resp-tabs-container {
  padding: 0px;
  background-color: transparent;
  float: left;
  width: 78%;
  min-height: 250px;
  border-radius: 0;
  clear: none;
  h3 {
    margin-top:0;
  }

  .btn{
    &.previousstep {
      width: 142px !important;
      @include media-breakpoint-down(sm) {
        width: 100% !important;
        max-width: none;
      }
    }

    @include media-breakpoint-down(sm) {
      margin-right: 0;
      width: 100% !important;
      max-width: none;
    }
  }
}

.resp-vtabs .resp-tab-content {
  border: none;
  word-wrap: break-word;
}

.resp-vtabs li.resp-tab-active {
  position: relative;
  z-index: 1;
}

.resp-arrow {
  &:after {
  width: 0;
  height: 0;
  float: right;
  margin-top: 3px;
  margin-right: 8px;
  float: right !important;
  content: "\F067";
  font-family: 'FontAwesome';
  }
}

h2.resp-tab-active span.resp-arrow {
  &:after {
    float: right !important;
    font-family: 'FontAwesome';
    content: "\F068";
      }
}

/* Accordion styles */
h2.resp-tab-active {
  background: #DBDBDB;
}

.resp-easy-accordion h2.resp-accordion {
  display: block;
}

.resp-easy-accordion .resp-tab-content {
  border: 1px solid #c1c1c1;
}

.resp-easy-accordion .resp-tab-content:last-child {
  border-bottom: 1px solid #c1c1c1;
  /* !important;*/
}

.resp-jfit {
  width: 100%;
  margin: 0px;
}

.resp-tab-content-active {
  display: block;
}

h2.resp-accordion:first-child {
  border-top: 1px solid #c1c1c1;
  /* !important;*/
}
@media only screen and (min-width: 768px){
  .resp-tabs-container {
    padding: 30px!important;
  }
}
/*Here your can change the breakpoint to set the accordion, when screen resolution changed*/
@media only screen and (max-width: 768px) {
  ul.resp-tabs-list {
    display: block;
    width: 100% !important;
    float: none !important;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
    font-size: 0;

    li{
      display: inline-block !important;
      height: 92px;
    }
  }

  h2.resp-accordion {
    display: none;
  }

  .resp-vtabs .resp-tab-content {
    border: 0 none !important;
    padding: 14px 0 !important;

  }

  .resp-vtabs .resp-tabs-container {
    border: none;
    float: none;
    width: 100%;
    min-height: 100px;
    clear: none;
  }

  .resp-accordion-closed {
    display: none !important;
  }

  .resp-vtabs .resp-tab-content:last-child {
    border-bottom: 0 none !important;
  }
}

/* custom styles */

.resp-tabs-list {
  margin-top: 0!important;
}
.resp-tabs-container {
  border-radius: 0 !important;
  border: none !important;
}

.resp-tabs-list .resp-tab-item {
  background-color: transparent !important;
  border: 0 none;
  border-right: 6px solid $shadow-gray !important;
  margin-bottom: 0;

  @include media-breakpoint-down(sm) {
    border-right: 0 none !important;
    border-bottom: 6px solid $shadow-gray !important;
  }
}

.resp-tabs-list .resp-tab-item.resp-tab-active {
  color: $tertiary_color !important;
  background-color: transparent !important;
  border: 0 none !important;
  border-right: 6px solid $tertiary_color !important;

  @include media-breakpoint-down(sm) {
    border-right: 0 none !important;
    border-bottom: 6px solid $tertiary_color !important;
  }
}

.resp-tab-item span {
  display: block;
  font-weight: normal;
  font-family: "Gotham Book";
}

.resp-accordion.hor_1.resp-tab-active {
  color: #fff;
  background-color: $tertiary_color!important;
  border-top: none!important;
  border: none!important;
}

// Animated Step BLOCK
.msform {
  overflow: hidden;
  position: relative;
  background: white;
	border: 0 none;
  box-shadow: none;
  border: 1px solid $shadow-gray;
  border-radius: 5px;
  fieldset {
    padding: 20px 40px;
    box-sizing: border-box;
    position: relative;
    @include media-breakpoint-down(sm) {
      padding: 46px 18px 23px;
      position: relative;
    }
    .step-title{
      @include media-breakpoint-down(sm) {
        font-size: 2rem;
      }
    }
    .step-indicator{
      @include media-breakpoint-down(sm) {
        position: absolute;
        right: 18px;
        top: 25px;
        z-index: 1;
        margin: 0 0 !important;
      }
    }
    /*Hide all except first fieldset*/
    &:not(:first-of-type) {
      display: none;
    }
  }
  /*inputs*/
  input, 
  textarea {
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
  }
  .previousstep {
    width: 142px !important;
    @include media-breakpoint-down(sm) {
      width: 100% !important;
      max-width: none;
    }

    &:hover {
      i {
      -webkit-transition: all 0.3s ease;
          transform: translateX(-7px);
        }
    }
    i {
    padding-left: 0;
    }
  }

  .btn{
    @include media-breakpoint-down(sm) {
      width: 100% !important;
      max-width: none;
    }
  }
}


.step-desc {
  display: block;
  font-family: 'montserrat';
}

//blue background-color
.blue-bg {
  h3 {
    color: white;
  }
  .resp-vtabs {
    .resp-tab-active {
      background-color: #ABB9D4 !important;
      color:$tertiary_color;
    }
    .resp-tab-content {
      h3 {
        color:$tertiary_color;
      }
      a {
        color: $tertiary_color;
      }
    }
  }
}
