#back-to-top {
    width: 66px;
    overflow: hidden;
    z-index: 999;
    display: none;
    cursor: pointer;
    position: fixed;
    bottom: 120px;
    right: 0;
    background-color: rgba(0,0,0,0.3);
    color: $white;
    text-align: center;
    text-decoration: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    transition: all 0.4s linear;
    i,span {
        font-size: 46px;
        line-height: 54px;
        transition: all 0.4s linear;
    }
    &:hover {
        background-color: rgba(0,0,0,0.6);
        color: $white;
        i,span {
            -moz-transform: translateY(-7px);
            -webkit-transform: translateY(-7px);
            -o-transform: translateY(-7px);
            -ms-transform: translateY(-7px);
            transform: translateY(-7px);
        }
    }
}
