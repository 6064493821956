.uncg-story-share {
    padding: 12px 15px 5px;
  
    @include mq('tablet') {
      text-align: center;
      width: 90px;
      border: none !important;
      position: fixed;
      top: 102px;
      right: 0;
      padding: 15px 0 0;
      z-index: 999;
    }
  
    label {
      @include mq('tablet') {
        font-size: 15px;
      }
  
      font-size: 20px;
      color: #0F2044;
      text-transform: uppercase;
      font-weight: bold;
    }
  
    .uncg-story-share-social-links {
      margin: 0;
      padding: 0;
      list-style: none;
  
      li {
        margin-bottom: 15px;
  
        @include mq('tablet', 'max') {
          display: inline;
          margin-right: 20px;
          margin-bottom: 0;
        }
  
        &:last-child {
          margin-right: 0;
        }
  
        a {
          font-size: 28px;
          color: #0F2044;
          text-decoration: none;
        }
      }
    }
  }