// ======================
// BG Color Classes
// ======================
.bg-primary { background-color: $primary_color; }
.bg-secondary { background-color: $secondary_color; }
.bg-tertiary { background-color: $tertiary_color; }
.bg-white { background-color: $white; }
.bg-black { background-color: $black; }
.bg-transparent { background-color: transparent; }
.blue-bg { background: #042D61; }
.gray-bg, .grey-bg { background: #E4E4E4; }