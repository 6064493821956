.page-academic-programs {
    h1.article-header {
        text-transform: inherit;
        font-size: 48px;
    }
    .left-side {
        .text-dark-blue {
            font-variation-settings: "wght" 700;
            margin-bottom: 0;
        }
        .sidebar-offset-programs {
            @media only screen and (min-width: 1429px) {
                width: 318px;
                margin-left: -70px;
            }
            .form-block {
                padding: 15px 17px;
                border: 1px solid #E6E9ED;
                margin-top: 12px;
                position: relative;
                .filter-heading {
                    font-size: 16px;
                    font-family: $primary-bold-font;
                    color: $defaultblue;
                    margin-top: 15px;
                }
                .custom-form {
                    .check-form-group {
                        .custom-control-label {
                            font-size: 14px;
                            padding-left: 17px;
                            font-family: $secondary-font;
                        }
                        input[type=checkbox], input[type=radio] {
                            width: 17px;
                            height: 17px;
                            transform: translateY(1px);
                            &:focus, &:focus-within {
                                outline: none !important;
                            }
                        }
                        input[type=checkbox]:checked, input[type=radio]:checked {
                            border: solid 2px $defaultblue;
                            background-color: $defaultblue;
                            &:focus, &:focus-within {
                                outline: none !important;
                            }
                            &::before {
                                background-color: $white;
                            }
                        }
                    }
                    @media only screen and (max-width: 991px) {
                        button.btn.btn-secondary {
                        display: none;
                        }
                    }
                    
                }
                .clear-filter {
                    position: absolute;
                    right: 17px;
                    top: 18px;
                    font-size: 16px;
                    @media only screen and (max-width: 991px) {
                        position: initial;
                        display: block;
                        text-align: center;
                        margin-top: 10px;
                    }
                }
                .custom-control {
                    padding: 4px 0;
                }

                @media only screen and (max-width: 991px) {
                    .mobile-dropdown-select-filter {
                        .filter-heading {
                            position: relative;
                            font-family: $secondary-font;
                            color: #222222;
                            display: block;
                            background-color: $secondaryblue;
                            border-left: solid 1px #BFBFBF;
                            border-right: solid 1px #BFBFBF;
                            border-bottom: solid 1px #BFBFBF;
                            border-top: solid 1px #BFBFBF;
                            padding: 9px 16px;
                            border-radius: 2px 2px 2px 2px;
                            font-size: 14px;
                            margin-bottom: 0;
                            cursor: pointer;
                            &::after {
                                display: inline-block;
                                margin-left: 8px;
                                vertical-align: inherit;
                                font-family: "Font Awesome 6 Free";
                                content: "\f078";
                                line-height: 0;
                                position: absolute;
                                right: 16px;
                                top: 20px;
                            }
                        }
                        .checkbox-filters-wrap {
                            padding: 0 18px 14px;
                            border-left: solid 1px $defaultblue;
                            border-right: solid 1px $defaultblue;
                            border-bottom: solid 1px $defaultblue;
                            border-radius: 0 0 2px 2px;
                            display: none;
                        }
                        &.dropdown-filter.active {
                            .filter-heading {
                                font-family: $secondary-font;
                                color: #222222;
                                display: block;
                                background-color: $white;
                                border-left: solid 1px $defaultblue;
                                border-right: solid 1px $defaultblue;
                                border-top: solid 1px $defaultblue;
                                border-bottom: none;
                                padding: 9px 16px;
                                border-radius: 2px 2px 0 0;
                                font-size: 14px;
                                margin-bottom: 0;
                                &::after {
                                    display: inline-block;
                                    margin-left: 8px;
                                    vertical-align: inherit;
                                    font-family: "Font Awesome 6 Free";
                                    content: "\f077";
                                    line-height: 0;
                                }
                            }
                            .checkbox-filters-wrap {
                                display: block;
                            }
                        }
                    }
                }
                @media only screen and (max-width: 991px) {
                    padding: 0;
                    border: none;
                    margin-bottom: 40px;
                }
            }
        }
        //employee directory sidebar
        .form-control-lg, .form-select.filters-select {
            margin-bottom: 10px;
            border-radius: 3px;
            border: solid 1px $defaultblue;
            background-color: $white;
            font-family: $secondary-font;
            font-size: 18px;
            color: #222222;
            &[type=text] {
                background-image: url(../../images/submit-arrow-mobile.svg);
                background-repeat: no-repeat;
                background-position: 98% 9px;
                background-size: 35px 35px;
                padding: 14px 48px 14px 20px;
            }
            &::placeholder {
                color: #222222;
                opacity: .55;
            }
        }
        .form-control-lg {
            padding: 14px 20px;
        }
        .btn.btn-primary.clear-filters {
            background-color: transparent;
            color: #3264A6;
            font-family: "Open Sans Regular";
            text-transform: capitalize;
            width: auto !important;
            margin: auto;
            padding: 8px 12px;
        }
        .form-control-lg {
            font-variation-settings: 'wght' 440;
            &::placeholder {
                color: #222222;
            }
        }
    }
    .right-side {
        p, span {
            &.found.cat-title {
                font-family: $secondary-font;
                font-variation-settings: "wght" 700;
                font-size: 18px;
                @media only screen and (max-width: 991px) {
                    display: block;
                    margin-bottom: 15px;
                }
            }
            &.found span {
                font-style: italic;
            }
        }
        .category {
            padding: 38px 0 25px;
            border-top: 2px solid $secondaryblue;
            margin-top: 30px;
            @media only screen and (max-width: 991px) {
                padding: 0 0 25px;
                border-top: none;
                margin-top: 0;
            }
            .category-items {
                display: inline-block;
            }
            .filter-result-item {
                background-color: $white;
                border: solid 1px $red;
                color: #000;
                font-weight: bold;
                padding: 1px 12px 1px 27px;
                display: inline-block;
                cursor: pointer;
                font-size: 14px;
                position: relative;
                border-radius: 60px;
                font-variation-settings: "wght" 400;
                &::after {
                    font-family: "Font Awesome 6 Free";
                    content: "\f00d";
                    color: #222222;
                    margin-left: 5px;
                    font-size: 18px;
                    position: absolute;
                    top: -2px;
                    left: 4px;
                }
                &:hover {
                    background-color: $red;
                    color: $white;
                    &::after {
                        color: $white;
                    }
                }
            }
        }
        p.detail {
            font-variation-settings: 'wght' 400;
            margin-bottom: 5px;
        }
        .card-text.credentials {
            font-variation-settings: "wght" 300;
        }

        .btn-group {
            .btn.btn-outline-primary {
                color: #042D61;
                border: 2px solid #042D61;
                background: transparent;
                border-radius: 4px;
                font-variation-settings: "wght" 650 !important;
                padding: 14px 41px 14px 61px;
                &::after {
                    display: inline-block;
                    margin-left: 12px;
                    vertical-align: inherit;
                    font-family: $fontawesome;
                    font-weight: 400;
                    content: "\f00c";
                    font-size: 23px;
                    line-height: 0;
                    opacity: 0;
                }
            }
            .btn-check:checked + .btn {
                color: #ffffff;
                background-color: #042D61;
                border-color: #042D61;
                &::after {
                    display: inline-block;
                    margin-left: 12px;
                    vertical-align: inherit;
                    font-family: $fontawesome;
                    font-weight: 400;
                    content: "\f00c";
                    font-size: 23px;
                    line-height: 0;
                    opacity: 1;
                }
            }
            > .btn:not(:last-child):not(.dropdown-toggle) {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                margin-right: 15px;
            }
            > .btn:nth-child(n+3) {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
        }
    }
    .card-title {
        color: $white;
    }
    .form-control-lg {
        border-radius: 0;
        font-family: "Open Sans Regular";
        font-variation-settings: 'wght' 650;
        margin-bottom: 30px;
        font-size: 16px;
        padding: 0.8rem 1rem;
        &::placeholder {
            color: #222222;
        }
    }
}

.page-academic-programs-v1 {
    .program-detail {
        a {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .found {
        border-bottom: 1px solid #D6D6D6;
        padding: 35px 0;
        margin-bottom: 0;
    }
    .program-listing {
        border-bottom: 1px solid #D6D6D6;
        padding: 10px 20px;
        display: block;
    }
    .credentials {
        display: grid;
        align-self: center;
        justify-items: end;
    }
    .credential {
        span {
            padding: 9px 13px;
            background: #EAEAEA;
            border: 1px solid #042D61;
            border-radius: 5px;
            display: inline-block;
            color: #222222;
            font-size: 14px;
            font-weight: bold;
            margin: 0 5px;
        }
    }
    .left-side {
        padding-right: 20px;
    }
    .right-side {
        border-left: 1px solid #D6D6D6;
        padding-left: 20px;
    }
}


.page-academic-programs-v2 {

    .card.program {
        margin-bottom: 20px;
        border: none;
        .card-text {
            color: $white;
        }
        .card-body {
            position: absolute;
            bottom: 0;
            width: 100%;
            background: #3264A6;
            color: $white;
        }
        &:hover {
            .card-body {
                bottom: 0;
                top: 0;
                .card-body-position {
                    position: absolute;
                    bottom: 0;
                    padding-bottom: 30px;
                }
            }
        }
        img.card-img-top {
            border-radius: 0;
        }
    }


    .filter-by {
        color: $white;
        display: inline-block;
        font-family: "Open Sans Regular";
        font-variation-settings: 'wght' 700;
        font-size: 18px;
    }
    .filter-controls {
        background: #042D61;
        padding: 20px 20px 40px;
        display: block;
        .filter-by, .form-select {
            margin-right: 10px;
        }
        .form-select {
            border-radius: 3px;
        }
    }
    .search-form {
        display: block;
        position: relative;
        .btn-search {
            color: #3264A6;
            background: none;
            position: absolute;
            top: 0;
            right: 0;
            padding: 14px 20px;
        }
    }
    .filter-results {
        padding: 10px 0 40px 0;
        .category {
            display: block;
            margin-bottom: 3px;
        }
        .category-items {
            display: inline-block;
        }
        .cat-title {
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
        }
        .filter-result-item {
            background-color: #EAEAEA;
            color: $black;
            font-weight: bold;
            padding: 6px 27px 6px 12px;
            display: inline-block;
            cursor: pointer;
            font-size: 14px;
            position: relative;
            &::after {
                font-family: $fontawesome;
                content: '\f00d';
                color: #DC3545;
                margin-left: 5px;
                font-size: 18px;
                position: absolute;
                top: 3px;
                right: 8px;
            }
        }
        .btn-clear-filters {
            font-size: 16px;
            border: 1px solid $black;
            color: #172B54;
            float: right;
            padding: 10px 12px;
            font-family: "Open Sans Regular";
            text-transform: capitalize;
            &::before {
                font-family: $fontawesome;
                content: '\f057';
                color: #DC3545;
                font-size: 18px;
                padding-right: 5px;
            }
        }
    }
}
.program-listings-wrap {
    margin: 0;
    padding: 0;
    list-style-type: none;
    z-index: 0;
    .program-listings-card {
        background-color: $secondaryblue;
        padding: 15px;
        &:hover, &:focus-within {
            outline: solid 3px $red;
            z-index: 1;
            position: relative;
            .program-listings-title {
                a {
                    text-decoration: underline;
                }
            }
        }
        .program-listings-title {
            margin-bottom: 15px;
            line-height: 28px !important;
            a {
                font-size: 24px;
                color: $defaultblue;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .program-listings-degree-type, .program-listings-area-of-study {
            font-size: 16px;
            font-family: $secondary-font;
            font-variation-settings: "wght" 400;
            line-height: 12px;
            span {
                font-family: $secondary-font;
                font-variation-settings: "wght" 700;
            }
        }
        .more-info {
            .more-less-collapse {
                text-align: right;
                .toggle-more {
                    color: $defaultblue;
                }
                a.collapsed.toggle-more {
                    color: $defaultblue;
                    &::before {
                        content: "More";
                    }
                    &::after {
                        display: inline-block;
                        vertical-align: inherit;
                        font-family: "Font Awesome 6 Free";
                        content: "\f078";
                        line-height: 0;
                        font-size: 12px;
                    }
                }
                a.toggle-more {
                    &::before {
                        content: "Less";
                    }
                    &::after {
                        display: inline-block;
                        vertical-align: inherit;
                        font-family: "Font Awesome 6 Free";
                        content: "\f077";
                        line-height: 0;
                        font-size: 12px;
                    }
                }
            }
            .card-body {
                background: transparent;
                border: none;
                padding: 0;
                a {
                  .learn-more {
                    position: absolute;
                    width: 1px;
                    height: 1px;
                    padding: 0;
                    margin: -1px;
                    overflow: hidden;
                    clip: rect(0, 0, 0, 0);
                    white-space: nowrap;
                    border: 0;
                    }  
                }
                
            }
        }
    }
}