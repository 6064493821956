.page-heading {
    h1, h2, h3, h4, h5, h6 {
        padding-bottom: 15px;
        margin-bottom: 25px;
        &.border-bottom {
            border-bottom: solid 1px $defaultblue !important;
        }
    }
    h1 {
        @media only screen and (max-width: 768px) {
            line-height: 55px;
        }
    }
}
.featured-image {
    background-image: url(../../images/standard-inner-feat-img.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    height: 417px;
    @media only screen and (min-width: 1400px) {
        height: 556px;
    }
    @media only screen and (max-width: 768px) {
        height: 220px;
    }
}