.spotlight-offset {

  .spotlight-header {
    @include mq( 'tablet-medium' ) {
      float: right;
      width: 60%;
      padding-left: 30px;
    }
    @include mq( 'tablet-medium', max ) {
      h2 {
        text-align: center;
      }
    }
  }

  .spotlight-featured-img {
    @include mq( 'tablet-medium' ) {
      float: left;
      width: 40%;
    }
    img {
      @include mq( 'tablet-medium', 'max' ) {
        padding: 10px 10px 0;
      }
    }
  }

  .spotlight-content {
    @include mq( 'tablet-medium' ) {
      position: absolute;
      top: 15%;
      left: 35%;
      max-width: 570px;
    }
    @include mq( 'tablet-medium', 'max' ) {
      position: relative;
      .btn-primary {
        display: block;
      }
    }
    .spotlight-title {
      font-size: 18px;
      font-family: $secondary-bold-font, sans-serif;
      color: #262626;
    }
    .spotlight-subtitle {
      font-size: 16px;
      font-family: $secondary-font, sans-serif;
      color: #262626;
    }
    .spotlight-excerpt {
      font-size: 18px;
      color: #3C3C3C;
      background-image: url(../images/microsite-quote.svg);
      background-repeat: no-repeat;
      background-size: 68px 58px;
      background-position: left top;
      padding: 40px 0 75px 15px;
    }
  }

}
