body.mobile-menu-open {
  @media only screen and (max-width: 1405px) {
    position: fixed;
    width: 100%;
    height: 100%;
  }
}

.clear-fix {
  clear: both;
}
.header-wrap {
  background-color: $white;
  z-index: 998;
  position: relative;
  .header-center-white {
    background: $white;
    box-shadow: 0 5px 5px rgb(0 0 0 / 16%);
    @media only screen and (max-width: 1405px) {
      border-bottom: solid 1px $defaultblue;
    }
  }
  .header-center {
    max-width: 1440px;
    margin: auto;
    @media only screen and (max-width: 1440px) {
      padding: 0 30px;
    }
    @media only screen and (max-width: 1405px) {
      padding: 14px 30px;
    }
  }
  .logo-wrap {
    padding: 22px 60px 22px 0;
    float: left;
    @media only screen and (max-width: 1405px) {
      padding: 0;
    }
    .site-logo {
        height: 47px;
        width: 269px;
        background-image: url(../../images/WS-Logo.png);
        text-indent: -9999px;
        overflow: hidden;
        display: inline-block;
        background-repeat: no-repeat;
        background-size: contain;
        vertical-align: middle;
        color: $defaultblue;
        background-color: $white;
        @media only screen and (max-width: 1405px) {
          background-image: url(../../images/WS-Logo.png);
          height: 27px;
          width: 156px;
        }
    }
    @media only screen and (max-width: 1240px) {
      float: left;
    }
  }

  .btn-apply {
    border: solid 2px $red;
    line-height: 39px;
    display: block;
    text-align: center;
    min-width: 155px;
    float: left;
    border-radius: 60px;
    font-family: $secondary-font, sans-serif;
    font-variation-settings: 'wght' 700;
    text-transform: uppercase;
    color: $defaultblue;
    text-decoration: none;
    margin: 24px 0 24px 44px;
    &:hover {
      background-color: $red;
      color: #ffffff;
    }
    @media only screen and (max-width: 1405px) {
      display: none;
    }
  }
  .menu-item-float-right {
    float: right;
  }
}


  /*
   *	Accessible Multi-Level Dropdown Navigation Menu
   *	Created by Ire Aderinokun
   *
   */
  /* Resets */
  header {
    #nav,
    #nav ul,
    #nav li {
      margin: 0;
      padding: 0;
      border: 0;
      list-style: none;
      box-sizing: border-box;
    }
    
    #nav {
      position: relative;
      min-height: 45px;
      max-width: 100%;
      background-color: $white;
      color: #000;
      float: left;
      z-index: 999;
      @media only screen and (max-width: 1405px) {
        display: none !important;
      }
      li.parentMenu-item {
        margin-right: 60px;
        &:last-child {
          margin-right: 0;
        }
        @media only screen and (max-width: 1405px) {
          margin-right: 51px;
        }
      
        > a {
          line-height: 92px;
      
          &::after {
            content: "";
            position: absolute;
            bottom: 25px;
            left: 0;
            right: 0;
            margin-left: -4px;
            margin-right: -4px;
            height: 2px;
            background-color: transparent;
          }
      
          &:hover {
            &::after {
              content: "";
              position: absolute;
              bottom: 25px;
              left: 0;
              right: 0;
              margin-left: -4px;
              margin-right: -4px;
              height: 2px;
              background-color: $red;
            }
          }
        }
      
        &:hover {
          
      
          >a {
            line-height: 92px;
      
            &::after {
              content: "";
              position: absolute;
              bottom: 25px;
              left: 0;
              right: 0;
              margin-left: -4px;
              margin-right: -4px;
              height: 2px;
              background-color: red;
            }
          }
        }
      }
      li.parentMenu-item:focus-within {
        
      
        > a {
          line-height: 92px;
      
          &::after {
            content: "";
            position: absolute;
            bottom: 25px;
            left: 0;
            right: 0;
            margin-left: -4px;
            margin-right: -4px;
            height: 2px;
            background-color: $red;
          }
        }
      
        &:hover {
          
      
          >a {
            line-height: 92px;
      
            &::after {
              content: "";
              position: absolute;
              bottom: 25px;
              left: 0;
              right: 0;
              margin-left: -4px;
              margin-right: -4px;
              height: 2px;
              background-color: red;
            }
          }
        }
      }
    }
    

    
    @media screen and (max-width: 650px) {
      #nav {
        display: block;
      }
    }
    
    #nav li {
      /* position: relative; */
    }
    
    #nav a {
      text-decoration: none;
      height: 100%;
      display: block;
      padding: 0 8px;
    }
    
    @media screen and (min-width: 650px) {
      #nav a:focus {
        /* outline: none; */
      }
    }
    
    .plusMark {
      margin-left: 10px;
      font-size: 20px;
      font-weight: 700;
      line-height: normal;
      display: none;
    }
    
    @media screen and (min-width: 650px) {
      #nav li {
        text-align: left;
        width: 100%;
      }
    }
    
    @media screen and (max-width: 650px) {
      #nav li {
        text-align: center;
        width: 100%;
      }
    }
    
    /* Any sub menu */
    @media screen and (min-width: 650px) {
      a+ul {
        position: absolute;
      }
    
      a+ul:not(.js-showElement) {
        display: none;
      }
    }
    
    @media screen and (max-width: 650px) {
      a+ul {
        position: relative;
      }
    
      a+ul:not(.js-hideElement) {
        display: block;
      }
    }
    
    /* The Main Navigation Bar - Navigation Level One */
    #nav>ul {
      &::after {
        content: "";
        clear: both;
      }
    }
    #nav>ul,
    .fa {
      height: 100%;
      line-height: 45px;
    }
    
    #nav>ul>li {
      position: relative;
      text-align: center;
    }
    
    @media screen and (min-width: 650px) {
      #nav>ul>li {
        float: left;
        width: auto;
      }
    }
    
    @media screen and (max-width: 650px) {
      #nav>ul>li {
        float: none;
        display: block;
        width: 100%;
      }
    }
    
    #nav>ul>li>a {
      background-color: $white;
      color: $defaultblue;
      font-family: $primary-bold-font;
      font-size: 18px;
    }
    
    #nav>ul>li>a:hover,
    #nav>ul>li>a:focus,
    #nav>ul>li>a.js-openSubMenu {
      background-color: $white;
    }
    
    #nav>ul>li:hover>a,
    #nav>ul>li:focus>a {
      background-color: $white;
    }
    
    @media screen and (min-width: 650px) {
      #nav>ul>li:not(:last-child) {
        /* border-right: 1px solid #D9BA31; */
        border-bottom: none;
      }
    }
    
    @media screen and (max-width: 650px) {
      #nav>ul>li:not(:last-child) {
        border-right: none;
      }
    
      #nav>ul>li:not(:last-child):not(:first-child) {
        border-bottom: 1px solid #D9BA31;
      }
    }
    
    #nav>ul>li:not(#toggleMenu):not(.js-showElement) {
      /* first level nav li except toggleMenu icon */
    }
    
    @media screen and (min-width: 650px) {
      #nav>ul>li:not(#toggleMenu):not(.js-showElement) {
        display: inline-block;
      }
    }
    
    @media screen and (max-width: 650px) {
      #nav>ul>li:not(#toggleMenu):not(.js-showElement) {
        display: none;
      }
    }
    
    @media screen and (min-width: 650px) {
      #nav #toggleMenu {
        display: none;
      }
    }
    
    @media screen and (max-width: 650px) {
      #nav #toggleMenu {
        display: block;
        width: 100%;
      }
    
      #nav #toggleMenu.js-open {
        border-bottom: 1px solid #D9BA31;
      }
    
      #nav #toggleMenu.js-open .fa-times {
        display: block;
      }
    
      #nav #toggleMenu.js-open .fa-bars {
        display: none;
      }
    
      #nav #toggleMenu.js-open a {
        background-color: $white;
      }
    
      #nav #toggleMenu:not(.js-open) .fa-times {
        display: none;
      }
    
      #nav #toggleMenu:not(.js-open) .fa-bars {
        display: block;
      }
    }
    
    span#toggleMenu-text {
      position: absolute;
      opacity: 0;
    }
    
    /* Second Level Dropdown */
    #nav>ul>li>ul {
      background-color: $secondaryblue;
    }
    
    @media screen and (min-width: 650px) {
      #nav>ul>li>ul {
        top: 92px;
        left: -30px;
        box-shadow: 4px 8px 5px rgba(0,0,0,.1);
        padding: 30px 0 30px 30px;
      }

      
    }
    @media screen and (max-width: 2100px) {
      #nav>ul>li.parentMenu-item.edge>ul {
        right: -30px !important;
        left: inherit;
        box-shadow: -8px 8px 5px rgba(0,0,0,.1);
        padding: 30px 30px 30px 0;
      }
    }
    
    
    @media screen and (max-width: 650px) {
      #nav>ul>li>ul {
        width: 100%;
        position: relative;
      }
    
      #nav>ul>li>ul:not(.js-showElement) {
        display: none;
      }
    }
    
    #nav>ul>li>ul>li>a {
      /* background-color: $white; */
      color: $defaultblue;
      font-family: "Open Sans", sans-serif;
      font-size: 17px;
      white-space: nowrap;
      padding-right: 48px;
      border-left: solid 2px $secondaryblue;
      font-variation-settings: "wght" 700;
    }

    #nav>ul>li.parentMenu-item.edge>ul>li>a {
      @media screen and (max-width: 2100px) {
        padding-left: 48px;
        padding-right: 8px;
        text-align: left;
      }
    }

    #nav>ul>li.parentMenu-item.edge>ul>li>a {
      @media screen and (max-width: 2100px) {
        border-right: solid 2px $secondaryblue;
        border-left: none;
      }
    }
    
    #nav>ul>li>ul>li>a:hover,
    #nav>ul>li>ul>li>a:focus,
    #nav>ul>li>ul>li:hover>a,
    #nav>ul>li>ul>li:focus>a,
    #nav>ul>li>ul>li:focus-within>a {
      background-color: $white;
      border-left: solid 2px $red;
    }

    #nav>ul>li.parentMenu-item.edge>ul>li>a:hover,
    #nav>ul>li.parentMenu-item.edge>ul>li>a:focus,
    #nav>ul>li.parentMenu-item.edge>ul>li:hover>a,
    #nav>ul>li.parentMenu-item.edge>ul>li:focus>a,
    #nav>ul>li.parentMenu-item.edge>ul>li:focus-within>a {
      @media screen and (max-width: 2100px) {
        border-right: solid 2px $red;
        border-left: none;
      }
    }
    
    #nav>ul>li>ul>li:not(:last-child) a {
      font-family: "Open Sans", sans-serif;
      font-size: 17px;
      white-space: nowrap;
    }
    
    /* Third Level Dropdown */
    @media screen and (min-width: 650px) {
      #nav>ul>li>ul>li>ul {
        top: 0;
        /* bottom: 0; */
        padding: 30px;
        left: 100%;
        background-color: $white;
        box-shadow: 4px 8px 5px rgba(0,0,0,.1);
        min-height: 100%;
        width: fit-content;
        /* width of ul */
      }
    }

    @media screen and (max-width: 2100px) {
      #nav>ul>li.parentMenu-item.edge>ul>li>ul {
        top: 0;
        /* bottom: 0; */
        padding: 30px;
        left: 1px !important;
        background-color: $white;
        box-shadow: -8px 8px 5px rgba(0,0,0,.1);
        min-height: 100%;
        width: fit-content;
        min-width: 100%;
        transform: translate(-100%, 0);
        /* width of ul */
      }
    }
    
    @media screen and (max-width: 650px) {
      #nav>ul>li>ul>li>ul {
        width: 100%;
        position: relative;
      }
    
      #nav>ul>li>ul>li>ul:not(.js-showElement) {
        display: none;
      }
    }
    
    #nav>ul>li>ul>li>ul>li>a {
      color: $defaultblue;
      font-family: "Open Sans", sans-serif;
      font-weight: 400 !important;
      font-size: 17px;
      &:hover {
        text-decoration: underline;
      }
    }

    #nav>ul>li.parentMenu-item.edge>ul>li>ul>li>a {
      @media screen and (max-width: 2100px) {
        color: $defaultblue;
        font-family: "Open Sans", sans-serif;
        font-weight: 400 !important;
        font-size: 17px;
        text-align: left;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    
    #nav>ul>li>ul>li>ul>li>a:hover,
    #nav>ul>li>ul>li>ul>li>a:focus {
      background-color: $white;
    }
    
    #nav>ul>li>ul>li>ul>li:not(:last-child)>a {
      color: $defaultblue;
      font-family: "Open Sans", sans-serif;
      font-weight: 900;
      font-size: 17px;
      white-space: nowrap;
      &:hover {
        text-decoration: underline;
      }
    }
    
    /* Javascript classes */
    #nav .js-hideElement {
      display: none;
    }
    
    #nav .js-showElement {
      display: block;
    }
    
    /* Fallback for users without javascript */
    html.no-js li:hover>a+ul,
    html.no-js li:focus>a+ul {
      display: block;
    }
    
    @media screen and (max-width: 650px) {
    
      html.no-js #nav:hover>ul>li:not(#toggleMenu),
      html.no-js #nav:focus>ul>li:not(#toggleMenu) {
        display: block;
      }
    
      html.no-js #nav:hover li:hover>a+ul,
      html.no-js #nav:hover li:focus>a+ul,
      html.no-js #nav:focus li:hover>a+ul,
      html.no-js #nav:focus li:focus>a+ul {
        display: block;
      }
    }
}

.accordion-menu {
  background-color: #001641;
}
.accordion-menu--hidden {
  display: none;
}    
.accordion-menu__list,
.accordion-menu__sublist {
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
      ul {
          li {
              a.accordion-menu__link {
                  font-family: "Open Sans", sans-serif;
                  font-weight: 900;
                  font-size: 17px;
                  text-decoration: none;
                  color: #001641;
                  padding-top: 0.5rem;
                  padding-bottom: 0.5rem;
                  &.accordion-menu--active::after {
                      content: none;
                  }
              }
              ul {
                  background-color: #ffffff;
                  li {
                      a.accordion-menu__sublink {
                          color: #001641;
                          font-family: "Open Sans", sans-serif;
                          font-weight: 400;
                          font-size: 17px;
                      }
                  }
              }
              .accordion-menu__icon {
                  &::after, &::before {
                      background-color: #001641 !important;
                  }
              }
          }
      }
  }
}
.accordion-menu__list {
  .accordion-menu__link {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      color: #ffffff;
      font-family: $primary-bold-font;
      font-size: 36px;
      text-decoration: none;
      text-transform: inherit;
      position: relative;
      line-height: 2;
      &.accordion-menu--active::after {
          content: "";
          position: absolute;
          bottom: 15px;
          left: 0;
          right: 0;
          margin-left: -4px;
          margin-right: -4px;
          height: 2px;
          background-color: #E91540;
      }
      .accordion-menu__icon {
          position: relative;
          width: 16px;
          height: 16px;
          transition: .2s all; 
          margin-left: 10px;  
          &::before,
          &::after {
            content: "";
            display: block;
            background-color: #fff;
            position: absolute;		
            top: 50%; left: 0;
            transition: .35s;
            width: 100%;
            height: 3px; 
          }
        }
        .plusMark {
          display: none;
        }
    }
}

.accordion-menu__icon:before {
  transform: translateY(-50%);
}
.accordion-menu__icon:after {
  transform: translateY(-50%) rotate(90deg);
}
.accordion-menu--active .accordion-menu__icon:before {
  transform: translateY(-50%) rotate(-90deg);
        opacity: 0;
}
.accordion-menu--active .accordion-menu__icon:after {
  transform: translateY(-50%) rotate(0);
}
.accordion-menu__sublist {
  margin-left: 0;
  background: #E6E9ED;
  padding-left: 1.25rem;
}
.accordion-menu__sublink {
  display: block;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 900;
  font-size: 17px;
  text-decoration: none;
  color: #001641;
}

.accordion-menu-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
  overflow: scroll;
}
.accordion-menu-nav__link:not(:last-child) {
  margin-right: 1rem;
}
.accordion-menu-wrapper {
  display: none;
}
.accordion-menu-wrapper.active {
  display: block;
}


.mobile-menu {
  background-color: $defaultblue;
  padding: 0 30px 0;
  position: absolute;
  display: none;
  overflow: scroll;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 997;
  top: 0;
  flex-flow: column;
  @media only screen and (max-width: 768px) {
      padding: 12px 15px;
    }
      .mobile-menu-items-mobile {
          
      }
      .mobile-menu-quicklinks {
          padding: 60px 0;
          .apply-mobile-wrap {
              border-bottom: solid 1px #ffffff;
              padding-bottom: 15px;
              margin-bottom: 30px;
              .btn-apply {
                  border: solid 2px $red;
                  line-height: 39px;
                  display: block;
                  text-align: center;
                  min-width: 155px;
                  border-radius: 60px;
                  font-family: "Open Sans", sans-serif;
                  font-weight: 900;
                  text-transform: uppercase;
                  color: #ffffff;
                  text-decoration: none;
                  margin: 24px 0 24px 0;
                  background-color: $red;
                  font-size: 18px;
                  &:hover {
                    background-color: #ffffff;
                    color: $defaultblue;
                    border: solid 2px #ffffff;
                  }
                }
          }
            .btn-myws {
              font-family: "Open Sans", sans-serif;
              color: #fff;
              border: solid 1px $red;
              padding: 5px 18px;
              border-radius: 5px;
              text-decoration: none;
              display: block;
              text-align: center;
              position: relative;
              padding-left: 30px;
              &:hover {
                  background-color: $red;
              }
              &::before {
                  position: absolute;
                  background-image: url(../../images/user-icon.svg);
                  width: 18px;
                  height: 18px;
                  content: "";
                  background-repeat: no-repeat;
                  background-size: contain;
                  left: 50%;
                  top: 7px;
                  transform: translateX(-45px);
              }
            }

            .dropdown {
              .dropdown-toggle-btn {
                color: $white;
                background-color: transparent;
                line-height: 19px;
                font-size: 14px;
                padding: 8px 13px;
                border: solid 1px $white;
                display: block;
                width: 100%;
                text-align: left;
                margin-top: 20px;
                position: relative;
                &::after {
                  position: absolute;
                  top: 16px;
                  right: 13px;
                }
                &.show {
                  text-decoration: underline;
                  color: #222222;
                  background-color: $secondaryblue;
                  &::after {
                    transform: rotate(180deg);
                  }
                }
              }
              ul {
                transform: translate(0px, -37px) !important;
                border: solid 1px $white;
                left: 0 !important;
                right: 0 !important;
                border-radius: 0;
                li {
                  padding: 0 3px;
                  a {
                    color: $defaultblue;
                    border-radius: 3px;
                    line-height: 28px;
                    font-size: 16px;
                  }
                }
              }
            }
      }
      @media only screen and (min-width: 1406px) {
          display: none !important;
      }
}

.mobile-menu-hamburger {
  float: right;
  display: none;
  @media only screen and (max-width: 1405px) {
      display: inherit;
  }
  /* transform: scale(0.5, 0.5);
  margin: -13px -10px -13px 10px;
  display: none; */
  .hamburger {
      padding: 0;
      .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
          background-color: $defaultblue;
      }
  }
  @media only screen and (max-width: 1240px) {
      display: inherit;
  }
}


.utility-bar-wrap {
  background-color: #00102F;
  padding: 5px 0;
  @media only screen and (max-width: 1405px) {
      display: none;
      background-color: transparent;
    }
  .utility-bar-center {
      max-width: 1440px;
      margin: auto;
      @media only screen and (max-width: 1440px) {
        padding: 0 30px;
      }
      .utility-menu-inline {
          display: flex;
          padding-left: 0;
          margin: 0;
          list-style-type: none;
          float: right;
          li {
              padding: 0 15px;
              a {
                  color: $white;
                  font-family: "Open Sans", sans-serif;
                  line-height: 35px;
                  text-decoration: none;
                  position: relative;
                  font-size: 14px;
                  &:hover {
                      text-decoration: underline;
                  }
              }
              &.my-ws {
                  a {
                      &::before {
                          position: absolute;
                          background-image: url(../../images/user-icon.svg);
                          width: 18px;
                          height: 18px;
                          content: "";
                          background-repeat: no-repeat;
                          left: 50%;
                          top: 0px;
                          transform: translateX(-45px);
                          background-size: contain;
                      }
                  }
              }
              .dropdown {
                .dropdown-toggle-btn {
                  color: $white;
                  background-color: transparent;
                  line-height: 33px;
                  font-size: 14px;
                  &.show {
                    text-decoration: underline;
                    &::after {
                      transform: rotate(180deg);
                    }
                  }
                }
                ul {
                  transform: translate(0px, 32px);
                  box-shadow: 0 10px 10px rgb(0 0 0 / 15%), 0 2px 2px rgb(0 0 0 / 15%), 0 4px 4px rgb(0 0 0 / 15%), 0 8px 8px rgb(0 0 0 / 15%);
                  li {
                    padding: 0 3px;
                    a {
                      color: $defaultblue;
                      border-radius: 3px;
                      line-height: 24px;
                      font-size: 14px;
                    }
                  }
                }
              }
          }
      }
      .searchcontent {
          max-width: 231px;
          float: right;
          .sr-only{
              position:absolute;
              left:-10000px;
              top:auto;
              width:1px;
              height:1px;
              overflow:hidden;
              color: $white;
              background-color: $defaultblue;
            }
            
            .custom-search {
              position: relative;
              .custom-search-input {
                width: 100%;
                border: 1px solid #fff;
                border-radius: 60px;
                padding: 7px 40px 7px 20px;
                line-height: 1;
                box-sizing: border-box;
                outline: none;
                font-size: 14px;
                font-family: "Open Sans", sans-serif;
                background-color: rgba(255, 255, 255, .25);
                color: $defaultblue;
                &:focus-within, &:focus {
                  background-color: $white;
                  color: $defaultblue;
                }
                &::placeholder {
                  color: $white;
                }
                @media only screen and (max-width: 768px) {
                  border-radius: 0;
                  padding: 16px 20px 16px 60px;
                }
              }
              .custom-search-botton {
                position: absolute;
                right: 14px; 
                top: 5px;
                bottom: 3px;
                border: 0;
                background: $defaultblue;
                color: #fff;
                outline: none;
                margin: 0;
                padding: 0 10px;
                border-radius: 100px;
                z-index: 2;
                @media only screen and (max-width: 768px) {
                  top: 9px;
                  right: 14px;
                }
              }
              .custom-search-botton {
                background-image: url(../../images/submit-arrow.svg);
                border:0;
                display:block;
                height: 24px;
                width: 24px;
                text-indent: -9999px;
                overflow: hidden;
                cursor: pointer;
                background-color: transparent !important;
                color: $defaultblue !important;
                @media only screen and (max-width: 768px) {
                  background-image: url(../../images/search-icon-mobile.svg);
                  height: 38px;
                  width: 38px;
                  background-color: transparent;
                }
                &:focus-visible {
                  outline: 1px solid $white;
                  box-shadow: 0 0 0px 2px #1D5FCC;
                }
              }
          }
      } 
  }

  
}

.mobile-menu {
  .utility-bar-wrap {
      @media only screen and (max-width: 1405px) {
          display: block;
        }
        .utility-bar-center {
          padding: 30px 0 0;
          .searchcontent {
              float: inherit;
              width: 100%;
              display: inline;

              .custom-search {
                  .custom-search-input {
                      width: 100%;
                      border: 1px solid #fff;
                      border-radius: 60px;
                      padding: 7px 40px 7px 20px;
                      line-height: 1;
                      box-sizing: border-box;
                      outline: none;
                      font-size: 16px;
                      font-family: "Open Sans", sans-serif;
                      background-color: $white;
                      color: $defaultblue;
                      &::placeholder {
                          color: #535353;
                      }
                  }
                  .custom-search-botton {
                      background-image: url(../../images/submit-arrow-mobile.svg);
                      border: 0;
                      display: block;
                      height: 24px;
                      width: 24px;
                      text-indent: -9999px;
                      overflow: hidden;
                      cursor: pointer;
                      background-color: transparent !important;
                      top: 7px;
                      color: $defaultblue !important;
                  }
              }
            }
        }
  }
}

.utility-bar-nav-position {
  float: right;
  position: relative;
  z-index: 9999;
  .utility-bar-nav {
  
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      
      li {
        float: left;
        position: relative;
        padding: 0 15px;
  
        &.my-ws {
          a {
            &::before {
              position: absolute;
              background-image: url(../../images/user-icon.svg);
              width: 18px;
              height: 18px;
              content: "";
              background-repeat: no-repeat;
              left: 50%;
              top: 0px;
              transform: translateX(-45px);
              background-size: contain;
            }
            &::after {
              display: none;
            }
          }
        }
        
        &:hover, &.hover {
          z-index: 100;
          
          ul {
            left: 0;
            padding: 5px 0;
            li {
              padding: 0 3px;
              a {	
                color: $defaultblue;
                padding: 5px 10px;
                width: 100%;
                display: block;
                text-shadow: none;
                margin-top: -1px; /* collapses top border */
                background: $white;
                &::after {
                  display: none;
                }
                &:hover, &:focus, &:active {	
                  background: #E9ECEF;
                  border-radius: 3px;
                  text-decoration: underline;
                }
                
              }
            }          
          }
          
        }
        
      }
      
      a {	
        color: #ffffff;
        background-color: #00102F;
        line-height: 33px;
        font-size: 14px;
        position: relative;
        text-decoration: none;
        &:hover, &:focus, &:active {
          text-decoration: underline;
        }
        &::after {
          display: inline-block;
          margin-left: 0.255em;
          vertical-align: 0.255em;
          content: "";
          border-top: 0.3em solid;
          border-right: 0.3em solid transparent;
          border-bottom: 0;
          border-left: 0.3em solid transparent;
        }
      }
    
      a:hover, a:focus, a:active,
      li:focus-within a, li:focus a, li:hover a /* <- keeps the tab hover state on as the dropdown gets explored */ {	
        &::after {
          transform: rotate(180deg);
        }
      }
    
      ul {
        position: absolute;
        top: 100%;
        left: -9999em;
        width: 180px;
        background-color: $white;
        border-radius: 4px;
        box-shadow: 0 10px 10px rgb(0 0 0 / 15%), 0 2px 2px rgb(0 0 0 / 15%), 0 4px 4px rgb(0 0 0 / 15%), 0 8px 8px rgb(0 0 0 / 15%);
        z-index: 999;
        li {
          float: none;
          position: static;
          a {
            line-height: 24px;
            &:hover, &:focus, &:active {
              text-decoration: underline;
            }
          }
        }
      
      }
    }
  }
  
  
}
// Google Search Styles

.gsc-control-cse {
  position: relative;
  padding: 0 !important;
  background: transparent !important;
  border: none !important;
  overflow: hidden;
}

.gsc-input-box {
  border-radius: 60px;
  background-color: transparent !important;
  border: none !important;
}

form.gsc-search-box,
table.gsc-search-box {
  margin-bottom: 0 !important;
}

.gcse-search-box {
  border: 0 !important;
  background: #387deb !important;
}

.gcse-search-box-tools .gcse-search-box .gcse-input {
  padding-right: 0 !important;
}

#gs_tti50 {
  padding: 0 !important;
}



#gsc-i-id1 {
  width: 100%;
  border: 1px solid #fff !important;
  border-radius: 60px !important;
  padding: 7px 40px 7px 20px !important;
  line-height: 1;
  box-sizing: border-box !important;
  outline: none !important;
  font-size: 14px !important;
  font-family: "Open Sans", sans-serif !important;
  background: rgba(255, 255, 255, 0.25) !important;
  color: #FFF;
  height: 35px !important;
  margin-top: 1px !important;
}

#gsc-i-id2 {
  width: 100%;
  border: 1px solid #fff !important;
  border-radius: 60px !important;
  padding: 7px 40px 7px 20px !important;
  line-height: 1;
  box-sizing: border-box !important;
  outline: none !important;
  font-size: 14px !important;
  font-family: "Open Sans", sans-serif !important;
  background: $white !important;
  color: #001641;
  height: 35px !important;
  margin-top: 1px !important;
}

#gsc-i-id1:focus, #gsc-i-id2:focus {
  background: #fff !important;
  color: #001641;
}

#gsc-i-id1::-webkit-input-placeholder, #gsc-i-id2::-webkit-input-placeholder {
  color: #000 !important;
}

#gsc-i-id1::-as-input-placeholder, #gsc-i-id2::-as-input-placeholder {
  color: #fff;
}

#gsc-i-id1::-moz-placeholder, #gsc-i-id2::-moz-placeholder {
  color: #fff;
  opacity: 1;
}

#gsc-i-id1::-moz-placeholder, #gsc-i-id2::-moz-placeholder {
  color: #fff;
  opacity: 1;
}

.gsib_b {
  display: none !important;
}

.gsc-search-button-v2 {
  padding: 7.5px !important;
  outline: none !important;
  cursor: pointer;
  position: absolute;
  right: 22px;
  top: 3px;
  background-color: transparent !important;
  border-color: transparent !important;
  @media only screen and (max-width: 1405px) {
    right: 37px;
    top: 8px;
  }
  &:hover {
    cursor: pointer;
  }
}

.gsc-search-button-v2:focus {
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  background-image: none !important;
}

@media only screen and (max-width: 1405px) {
  #gsc-i-id1, #gsc-i-id1 {
    background: #FFF !important;
    color: #00102F !important;
  }

  .gsc-search-button-v2 svg {
    fill: #00102F !important;
  }
}
@media only screen and (min-width: 1405px) {
  #___gcse_0 {
  width: 217px;
  float: right;
  }
}


