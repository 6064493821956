/*
* Font Utilities
*/
.primary-font { font-family: $primary-font; }
.primary-bold-font { font-family: $primary-bold-font; }
.secondary-font { font-family: $secondary-font; }
.secondary-bold-font { font-family: $secondary-bold-font; }
.tertiary-font { font-family: $tertiary-font; }
.tertiary-bold-font { font-family: $tertiary-bold-font; }

p {
    font-family: $secondary-font;
}
strong {
    font-variation-settings: "wght" 700;
}