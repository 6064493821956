
$arrow-size: 1em;
$arrow-border: 0.2em;
.card-group {
    gap: 54px;
    .card.card-ws {
        border: none;
        padding: 26px;
        border-radius: 0;
        @media only screen and (max-width: 768px) {
            flex: inherit;
            width: 100%;
        }
        .card-body {
            padding: 0;
            .card-icon-link-enroll {
                height: 67px;
                width: 61px;
                background-image: url(../../images/enroll-icon.svg);
                text-indent: -9999px;
                overflow: hidden;
                display: inline-block;
                background-repeat: no-repeat;
                background-size: contain;
                vertical-align: middle;
                color: $defaultblue;
                margin-bottom: 21px;
            }
            .card-icon-link-transfer {
                height: 67px;
                width: 61px;
                background-image: url(../../images/transfer-icon.svg);
                text-indent: -9999px;
                overflow: hidden;
                display: inline-block;
                background-repeat: no-repeat;
                background-size: contain;
                vertical-align: middle;
                color: $defaultblue;
                margin-bottom: 21px;
            }
            .card-title a {
                font-size: 24px;
                color: $defaultblue;
                text-decoration: none;
            }
            p {
                font-size: 16px;
                color: $defaultblue;
            }
            .card-link {
                font-size: 18px;
                color: $defaultblue;
                text-decoration: none;
                &::after {
                    display: inline-block;
                    margin-left: 8px;
                    vertical-align: inherit;
                    font-family: $fontawesome;
                    content: "\f105";
                    line-height: 0;
                }
            }
        }
        &.card-default {
            &:hover, &:focus-within {
                .card-title a, .card-link {
                    text-decoration: underline;
                }
                .card-icon-link-enroll {
                    height: 67px;
                    width: 61px;
                    background-image: url(../../images/enroll-icon-hover.svg);
                    text-indent: -9999px;
                    overflow: hidden;
                    display: inline-block;
                    background-repeat: no-repeat;
                    background-size: contain;
                    vertical-align: middle;
                    color: $defaultblue;
                    margin-bottom: 21px;
                }
                .card-icon-link-transfer {
                    height: 67px;
                    width: 61px;
                    background-image: url(../../images/transfer-icon-hover.svg);
                    text-indent: -9999px;
                    overflow: hidden;
                    display: inline-block;
                    background-repeat: no-repeat;
                    background-size: contain;
                    vertical-align: middle;
                    color: $defaultblue;
                    margin-bottom: 21px;
                }
            }
            &:hover {
                .card-title a, .card-link {
                    text-decoration: underline;
                }
            }
        }
        &.card-primary {
            background-color: #E6E9ED;
            &:hover, &:focus-within {
                outline: solid 3px $red;
                .card-title a, .card-link {
                    text-decoration: underline;
                }
                .card-icon-link-enroll {
                    height: 67px;
                    width: 61px;
                    background-image: url(../../images/enroll-icon-hover.svg);
                    text-indent: -9999px;
                    overflow: hidden;
                    display: inline-block;
                    background-repeat: no-repeat;
                    background-size: contain;
                    vertical-align: middle;
                    color: $defaultblue;
                    margin-bottom: 21px;
                }
                .card-icon-link-transfer {
                    height: 67px;
                    width: 61px;
                    background-image: url(../../images/transfer-icon-hover.svg);
                    text-indent: -9999px;
                    overflow: hidden;
                    display: inline-block;
                    background-repeat: no-repeat;
                    background-size: contain;
                    vertical-align: middle;
                    color: $defaultblue;
                    margin-bottom: 21px;
                }
            }
        }
        &.card-secondary {
            background-color: #E6E9ED;
            padding: 10px;
            &:hover, &:focus-within {
                outline: solid 3px $red;
                .card-title a, .card-link {
                    text-decoration: underline;
                }
                .card-link {
                    
                    & {
                      &:before {
                        transform:  translate(3.3em, -($arrow-size / 2)) rotate(45deg);
                      }
                      &:after {
                        transform:  translate(3.3em, -($arrow-border / 2));
                        width: 4.3em;
                      }
                    }
                }
                .card-icon-link-enroll {
                    height: 67px;
                    width: 61px;
                    background-image: url(../../images/enroll-icon-hover.svg);
                    text-indent: -9999px;
                    overflow: hidden;
                    display: inline-block;
                    background-repeat: no-repeat;
                    background-size: contain;
                    vertical-align: middle;
                    color: $defaultblue;
                    margin-bottom: 21px;
                }
                .card-icon-link-transfer {
                    height: 67px;
                    width: 61px;
                    background-image: url(../../images/transfer-icon-hover.svg);
                    text-indent: -9999px;
                    overflow: hidden;
                    display: inline-block;
                    background-repeat: no-repeat;
                    background-size: contain;
                    vertical-align: middle;
                    color: $defaultblue;
                    margin-bottom: 21px;
                }
            }
            .card-link {
                position: relative;
                overflow: hidden;
                font-size: 12px;
                padding-left: 30px;
                span {
                    text-indent: -9999px;
                    overflow: hidden;
                    display: inline-block;
                }
                &:after, &:before {
                  content: "";
                  display: block;
                  position: absolute;
                  transition: all 0.5s ease-in-out;
                }
                &:before {
                  top: 50%;
                  right: $arrow-size;
                  width: $arrow-size;
                  height: $arrow-size;
                  transform-origin: 50% 50%;
                  transform:  translate(0, -($arrow-size / 2)) rotate(45deg);
                  box-shadow: inset -#{$arrow-border} $arrow-border 0 0 $defaultblue;
                  border-radius: 0 $arrow-size*0.15 0 0;
                }
                
                &:after {
                  top: 50%;
                  right: 1em;
                  width: $arrow-size * 1.3;
                  height: $arrow-border;
                  transform:  translate(0, -($arrow-border / 2));
                  background-color: $defaultblue;
                }
                &:hover {
                  &:before {
                    transform:  translate(3.3em, -($arrow-size / 2)) rotate(45deg);
                  }
                  &:after {
                    transform:  translate(3.3em, -($arrow-border / 2));
                    width: 4.3em;
                  }
                }
            }
        }
    }
}