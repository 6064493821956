/* Glyph, by Harry Roberts */

.hr-style {
    overflow: visible; /* For IE */
    padding: 0;
    border: none;
    border-top: solid 2px #FBC82E;
    position: relative;
}

.hr-style.hr-blue {
    overflow: visible; /* For IE */
    padding: 0;
    border: none;
    border-top: solid 2px $tertiary_color;
    position: relative;
}

.hr-style::after {
    content: "ooo";
    display: inline-block;
    position: absolute;
    top: -11px;
    font-size: 12px;
    padding: 0 0.25em;
    background: #fff;
    font-family: "Arial", sans-serif;
    letter-spacing: 2px;
    right: 0;
    color: #FBC82E;
    font-weight: bold;
}

.hr-style.hr-blue::after {
    color: $tertiary_color;
  }

.hr-style.hr-bgGrey::after, .hr-style.hr-bgGrey::before {
    background: $white;
}

.hr-style::before {
    content: "ooo";
    display: inline-block;
    position: absolute;
    top: -11px;
    font-size: 12px;
    padding: 0 0.25em;
    background: #fff;
    font-family: "Arial", sans-serif;
    letter-spacing: 2px;
    left: 0;
    color: #FBC82E;
    font-weight: bold;
}

.hr-style.hr-blue::before {
    color: $tertiary_color;
  }