.sidebar-offset {
    @media only screen and (min-width: 1440px) {
        width: 317px;
        margin-left: -50px;
        margin-top: -180px;
    }
    @media only screen and (max-width: 1439px) {
        padding-top: 56px;
        margin-top: -180px;
    }
    @media only screen and (max-width: 767px) {
        padding-top: 56px;
        margin-top: 0;
    }
}
.right-col-offset {
    @media only screen and (min-width: 1440px) {
        margin-top: -95px;
        background-color: $white;
        padding: 22px;
    }
}