.events-sidebar {
  .event-carousel-item {
    padding: 10px 15px;

    /* custom padding */
    &.mb-2 {
      margin-bottom: 20px !important;
    }
  }

  .event-date {
    background-color: transparent;
  }

  .event-date time span {
    font-size: 36px;
    line-height: 1;
  }

  .event-date time {
    font-size: 24px;
  }

  .event-date {
    width: 75px;
    height: 75px
  }

  h3 {
    font-family: $secondary-font, sans-serif !important;
    font-weight: bold !important;
    line-height: normal;
    margin-top: 0.5rem;
  }

  .event-carousel-item h3 a:hover {
    text-decoration: underline;
  }
}

.events-sidebar-blue {
  .event-carousel-item {
    .event-date {
      border: none;
    }

    &:hover {
      background: $white;

      .event-date {
        background: transparent !important;
      }
    }
  }
}

.event-carousel-item.events-sidebar-blue {
  .event-date {
    border: none;
  }

  &:hover {
    background: $white;

    .event-date {
      background: transparent !important;
    }
  }
}


.events-carousel {
  margin: 0 -15px;

  @include mq('tablet-large') {
    padding: 0 rem-calc(30);
  }

  .events-sidebar {
    margin: 0;
  }

  .event-carousel-item {
    &.h-100 {
      @include mq('phablet', 'max') {
        height: auto !important;
      }
    }

    margin: 0 15px rem-calc(30);
    padding: rem-calc(30);
    background: #ffffff;
  }

  .event-carousel-item.hvr-grow:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 55%) !important;
  }

  .slick-slide:hover~.slick-arrow {
    display: none;
  }

  @include mq('tablet-large', 'max') {

    .hvr-grow:active,
    .hvr-grow:focus,
    .hvr-grow:hover {
      transform: none !important;
    }

    .event-carousel-item.hvr-grow:hover {
      box-shadow: none !important;
    }
  }
}

.slider.events-carousel {
  .slick-track {
    display: flex !important;

    .slick-slide {
      height: inherit !important;
      position: relative;
      margin: 0 15px;

      > div:nth-child(1) {
        height: 100%;
        &:hover {
          height: inherit;
        }
        > div:nth-child(1) {
          height: 100%;
          &:hover {
            height: inherit;
          }
        }
      }

      @include mq('tablet-large', 'max') {
        margin: 0 10px;
      }

      .event-carousel-item.hvr-grow {
        width: 100%;
        height: 100%;

        &:hover {
          @include mq('tablet-large') {
            position: absolute;
            top: 0;
            left: 0;
            height: inherit !important;
            margin-top: -8%;
          }
        }
      }
    }
  }

  .slick-dots {
    bottom: -60px;
  }

  .event-date {
    @include mq('tablet-large', 'max') {
      width: 75px;
    }

    time {
      @include mq('tablet-large', 'max') {
        font-size: 20px;
      }
    }

    time span {
      @include mq('tablet-large', 'max') {
        font-size: rem-calc(30);
      }
    }

    .event-carousel-item h3 a {
      @include mq('tablet-large', 'max') {
        font-size: 16px;
      }
    }
  }

}

.event-date {
  font-size: 16px;
  line-height: 24px;
  box-sizing: border-box;
  margin-top: 6px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  min-height: 1px;
  background-color: #fff;
  color: #0F2044;
  font-family: 'Open Sans', sans-serif;
  padding: 15px 0 0;
  font-weight: 900;
  width: 100px;
  border: solid 1px $tertiary_color;

  time {
    text-transform: uppercase;
    color: #0F2044;
    font-family: $secondary-font, sans-serif;
    font-weight: 700;
    box-sizing: border-box;
    display: block;
    text-align: center;
    font-size: rem-calc(30);
    line-height: .8;

    span {
      font-family: $primary-bold-font, sans-serif;
      font-weight: 900;
      text-align: center;
      display: block;
      font-size: 44px;
      line-height: 1.4;
    }
  }
}

.events-no-carousel {
  .event-date {
    font-size: 16px;
    line-height: 24px;
    box-sizing: border-box;
    margin-top: 6px;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    min-height: 1px;
    background-color: #fff;
    color: #0F2044;
    font-family: 'Open Sans', sans-serif;
    padding: 15px 0 0;
    font-weight: 900;
    width: 75px;
    border: solid 1px $tertiary_color;

    time {
      text-transform: uppercase;
      color: #0F2044;
      font-family: $secondary-font, sans-serif;
      font-weight: 700;
      box-sizing: border-box;
      display: block;
      text-align: center;
      font-size: 24px;
      line-height: .6;

      span {
        font-family: $primary-bold-font, sans-serif;
        font-weight: 900;
        text-align: center;
        display: block;
        font-size: 36px;
        line-height: 1.22;
      }
    }
  }
  .event-carousel-item {
    @include mq( 'tablet-large', 'max' ) {
      transform: none !important;
    }
    .btn {
      @include mq( 'tablet-large', 'max' ) {
        width: max-content !important;
        margin: 0;
      }
    }
  }
}

.slick-slider.events-carousel .slick-list {
  height: inherit;
  margin-bottom: 60px
}

.events-carousel .slick-list {
  overflow: inherit;
  margin-bottom: 88px
}

.event-carousel-item h3 a {
  font-size: 20px;
  color: #0F2044;
  font-weight: 400;
  text-decoration: none;
  line-height: normal;
  font-family: $secondary-bold-font, sans-serif;
  font-weight: bold;
}

.slick-slider .event-carousel-item:hover .event-date,
.event-carousel-item:hover .event-date {
  background: $tertiary_color;
}

.slick-slider .event-carousel-item p,
.slick-slider .event-carousel-item .btn {
  display: none;
}

.slick-slider .event-carousel-item p a {
  color: $tertiary_color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.slick-slider .event-carousel-item:hover p,
.slick-slider .event-carousel-item:hover .btn {
  display: inline-block;

  @include mq('tablet-large', 'max') {
    display: none;
  }
}

.slick-slider.events-carousel {
  .event-carousel-item {
    margin: 0;
  }
}

.events-carousel-overflow {
  overflow: hidden;

  .fs-4.events-blurb {
    font-size: 22px !important;
    margin-bottom: rem-calc(30);
  }
  @include mq('tablet-large', 'max') {
    h2.page-header.page-header-center.pt-5.mt-5.fs-4.max-width-sm {
      padding-top: 0 !important;
    }
  }
}

.events-carousel.slick-slider .slick-track,
.events-carousel.slick-slider .slick-list {
  z-index: 1;
}

.events-carousel .slick-prev,
.events-carousel .slick-next {
  z-index: 0 !important;
  top: 115% !important;
}



  .event-carousel-item {
    .btn-link {
      color: $tertiary_color;
    }
  }

  .event-date {
    border: solid 1px $tertiary_color;
  }

  .slick-slider .event-carousel-item:hover .event-date,
  .event-carousel-item:hover .event-date {
    background: $tertiary_color !important;

    time {
      color: $white !important;
    }
  }

  .container.pb-5 {
    padding-bottom: rem-calc(30) !important;
  }

  h2.text-center.pt-5.mt-5 {
    margin-top: 0px !important;
  }
  .wp-block-events-news {
    .text-center .btn.btn-bordered.btn-primary.btn-rounded {
      background-color: transparent !important;
      border-color: $tertiary_color !important;
      color: $tertiary_color !important;
      &:hover {
        background-color: $tertiary_color !important;
        color: $white !important;
      }
    }
  }


.wf-theme-stem {
  .events-carousel-overflow {
    h2.text-center.mt-5.fs-4.text-uppercase {
      padding-top: 0 !important;
      font-size: rem-calc(32) !important;
    }
    .btn.btn-bordered.btn-primary.btn-rounded {
      border-radius: 0 !important;
    }
  }

  .event-carousel-item {
    .btn-link {
      color: $tertiary_color;
    }
  }

  .event-date {
    border: solid 1px $tertiary_color;
  }

  .slick-slider .event-carousel-item:hover .event-date,
  .event-carousel-item:hover .event-date {
    background: $tertiary_color !important;

    time {
      color: $white !important;
    }
  }

  .wp-block-events-news {
    .btn.btn-bordered.btn-bordered-blue.btn-rounded {
      border-radius: rem-calc(4) !important;
    }
  }
}

.wf-theme-research {
  .events-carousel-overflow {
    h2.text-center.mt-5.fs-4.text-uppercase {
      padding-top: 0 !important;
      font-size: rem-calc(32) !important;
    }
  }

  .event-carousel-item {
    .btn-link {
      color: $tertiary_color;

      &.btn-rt-icon {
        padding-left: 0 !important;
      }

      &:hover {
        background: transparent !important;
      }
    }
  }

  .event-date {
    border: dashed 1px $tertiary_color;
  }

  .slick-slider .event-carousel-item:hover .event-date,
  .event-carousel-item:hover .event-date {
    background: $tertiary_color !important;
    border: solid 1px $tertiary_color;
  }
}

.wf-featured-event {

  a {
    color: $tertiary_color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &:hover {
    border: 1px solid $tertiary_color !important;

    a {
      text-decoration: underline;
    }
  }

  .wf-featured-event-left {
    @include mq('tablet-large') {
      border-left: solid rem-calc(8) $tertiary_color;
    }

    @include mq('tablet-large', 'max') {
      border-top: solid rem-calc(8) $tertiary_color;
    }
  }

  .wf-featured-event-left {
    padding: rem-calc(30) 0;

    @include mq('tablet-large', 'max') {
      padding: rem-calc(30);
    }
  }
}

/* FORM */

.tribe-common--breakpoint-medium.tribe-common .tribe-common-form-control-text__input {
  padding: var(--tec-spacer-4) var(--tec-spacer-4) var(--tec-spacer-4) var(--tec-spacer-8) !important;
}

/* ALIGNMENT */
.wp-block-events-news {
  .text-center {
    display: flex;
    justify-content: center;
  }
}
