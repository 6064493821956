.cta-links-wrap {
  margin-bottom: 25px;
  display: inline-block;
  .cta-title {
    color: #3264A6;
    text-transform: capitalize;
    font-family: "Gotham Medium";
    font-weight: 400;
    line-height: 1.2;
    color: #3264A6;
    font-size: 24px;
    padding-bottom: 10px;
    margin-bottom: 8px;
    display: block;
    max-width: 10rem;
    text-align: center;
    @include media-breakpoint-down(md){
      font-size: 18px;
    }
    .sub-text {
      font-size: 16px;
      text-transform: uppercase;
      display: block;
      width: 100%;
      border-top: 1px solid #3264A6;
      margin: 8px 0 5px;
      padding-top: 15px;
      transition:0.8s;
      @include media-breakpoint-down(md){
        font-size: 16px;
      }
      &:hover {
        span {
          transform: translateX(10px);
        }
      }
    }
  }
  .cta-link {
    color: #3264A6;
    text-transform: capitalize;
    font-family: "Gotham Bold";
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
    @include media-breakpoint-down(md){
      font-size: 16px;
    }
    &:hover {
      text-decoration: none;
      .sub-text {
          text-decoration: none;
      }
      i {
        -webkit-transition: all 0.3s ease;
        transform: translateX(7px);
      }
    }
  }
  &.align-left {
    .cta-title {
      text-align: left !important;
    }
  }
  &.dark-cta {
    .cta-title {
      color: white;
    }
    .sub-text {
      color: #FFCE00;
      border-top: 1px solid white;
    }
  }
}
