
/**
* Columns Mix-in
**/
@mixin columns($count, $gap) {
    -moz-column-count: $count;
    -moz-column-gap: $gap;
    -webkit-column-count: $count;
    -webkit-column-gap: $gap;
    column-count: $count;
    column-gap: $gap;
  }