// =============
// Custom Font selections - Use these to select Primary, secondary fonts
// =============
$fontawesome: "Font Awesome 6 Free";
$primary-font: "Crimson Text";
$primary-bold-font: "Crimson Text Bold";
$secondary-font: "Open Sans";
$secondary-bold-font: "Open Sans";
$tertiary-font: "Open Sans";
$tertiary-bold-font: "Open Sans";

// ======================
// Fonts
// ======================
@include fontface-include($primary-font, "CrimsonText/CrimsonText-Regular", 'normal', '400');
@include fontface-include($primary-bold-font, "CrimsonText/CrimsonText-Bold", 'normal', '700');
@include fontface-include($secondary-font, "OpenSans/OpenSans", 'normal', '400');

// ======================
// Icon Libraries
// ======================

$fa-font-path: "/assets/fonts/";

@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';